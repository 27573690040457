import React, { useContext, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './ProfileMenu.css';
import { faCog, faPerson, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { signOut } from '@aws-amplify/auth';
import { GlobalContext } from '../tspace_components/contexts/GlobalStore';
import ProfileModal from './Modals/ProfileModal';
import AvatarModal from './Modals/AvatarModal';
const ProfileMenu = ({ isVisible, onClose }) => {
  const { currentUserProfile } = useContext(GlobalContext);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showAvatarModal, setShowAvatarModal] = useState(false);

  const profileImage = currentUserProfile?.Avatar;
  const username = currentUserProfile?.username || 'Unknown User';
  const tribePoints = currentUserProfile?.totalPoints || 0;

  const handleProfileSettingsClick = () => {
    setShowProfileModal(true);
    onClose(); // Close the profile menu
  };

  const handleAvatarClick = () => {
    setShowAvatarModal(true);
    onClose(); // Close the profile menu
  };

  return (
    <>
      <CSSTransition
        in={isVisible}
        timeout={300}
        classNames="dropdown"
        unmountOnExit
      >
        <div className="profile-menu">
          <div className="profile-header">
            {profileImage ? (
              <img src={profileImage} alt="Profile Avatar" className="profile-image" />
            ) : (
              <div className="profile-image-placeholder"></div>
            )}
            <div className="profile-menu-username">@{username}</div>
            <div className="points">{tribePoints} TP</div>
          </div>
          <div className="menu-divider"></div>
          <div className="menu-item" onClick={handleProfileSettingsClick}>
            <FontAwesomeIcon icon={faCog} fixedWidth size="lg" />
            <span className="menu-item-text">Profile Settings</span>
          </div>
          <div className="menu-item" onClick={handleAvatarClick}>
            <FontAwesomeIcon icon={faPerson} fixedWidth size="lg" />
            <span className="menu-item-text">Customize Avatar</span>
          </div>
          <div className="menu-divider"></div>
          <div className="menu-item" onClick={signOut}>
            <FontAwesomeIcon icon={faSignOut} fixedWidth size="lg" />
            <span className="menu-item-text">Sign Out</span>
          </div>
        </div>
      </CSSTransition>

      <ProfileModal isVisible={showProfileModal} onClose={() => setShowProfileModal(false)} />
      <AvatarModal isVisible={showAvatarModal} onClose={() => setShowAvatarModal(false)} />
    </>
  );
};

export default ProfileMenu;
