import React, { useContext, useEffect, useState } from 'react';
import './ProximityChat.css';
import { GlobalContext } from '../contexts/GlobalStore';
import { LiveKitContext } from '../contexts/LiveKitContext';
import { useSocket } from '../contexts/SocketContext';
import { useDialogue } from '../contexts/DialogueContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ProximityChat = () => {
    const {
        proximityChatUsers,
        openUserProfile,
        currentUserProfile,
        handleFollowUser,
        handleUnfollowUser,
        checkIfFollowing,
        getFollowRecordId,
        manageConnectionOnFollow,
        hasSpokenNear,
        setHasSpokenNear,
        isMobileView,
    } = useContext(GlobalContext);
    const { setChatUIOpen } = useDialogue();
    const { activeSpeakers, isJoined, connectToLiveKit } = useContext(LiveKitContext);
    const { room } = useSocket();

    const [followStatuses, setFollowStatuses] = useState({});
    const [showTooltip, setShowTooltip] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const maxVisible = isMobileView ? 1 : 3;

    useEffect(() => {
        if (!room) return;
        if (activeSpeakers.has(room.sessionId)) {
            const updatedHasSpokenNear = { ...hasSpokenNear };
            proximityChatUsers.forEach(user => {
                if (!updatedHasSpokenNear[user.profile.id]) {
                    updatedHasSpokenNear[user.profile.id] = true;
                }
            });
            setHasSpokenNear(updatedHasSpokenNear);
        }
    }, [activeSpeakers, room, proximityChatUsers]);

    useEffect(() => {
        updateFollowStatuses();
    }, [proximityChatUsers, currentUserProfile]);

    const updateFollowStatuses = async () => {
        const newStatuses = {};
        for (const user of proximityChatUsers) {
            if (user.profile && user.profile.id !== currentUserProfile.id) {
                const isFollowing = await checkIfFollowing(currentUserProfile.id, user.profile.id);
                newStatuses[user.profile.id] = isFollowing;
            }
        }
        setFollowStatuses(newStatuses);
    };

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, proximityChatUsers.length - maxVisible));
    };

    function handleClose() {
        setChatUIOpen(false);
    }

    const handleFollowButtonClick = async (profileId) => {
        await handleFollowUser(profileId);
        updateFollowStatuses();
        await manageConnectionOnFollow(currentUserProfile.id, profileId);
    };

    const handleJoinClick = () => {
        connectToLiveKit();
    };

    const handleNoClick = () => {
        setChatUIOpen(false);
    };

    /* return (
        <div className={`dialogue-box ${isMobileView ? 'mobile' : ''}`}>
            <img src={"/assets/CloseIcon.png"} alt="Close" className="close-button" onClick={handleClose} />
            {isJoined ? (
                <>
                    {proximityChatUsers.length > maxVisible && (
                        <FontAwesomeIcon icon={faChevronLeft} size="5x" className="chevron chevron-left" onClick={handlePrevClick} />
                    )}
                    <div className="user-container">
                        {proximityChatUsers.slice(currentIndex, currentIndex + maxVisible).map((user, index) => {
                            if (!user.profile) {
                                return null;
                            }
                            return (
                                <div key={user.profile.id} className={`user-box user-${index + 1}`}>
                                    <div
                                        className={`avatar ${activeSpeakers.has(room.sessionId) ? 'active-speaker' : ''}`}
                                        onClick={() => openUserProfile(user)}
                                    ></div>
                                    <div className="username" onClick={() => openUserProfile(user)}>
                                        {user.profile.id === currentUserProfile.id ? "Me" : user.profile.username}
                                    </div>
                                    {user.profile.id !== currentUserProfile.id && !followStatuses[user.profile.id] ? (
                                        <div
                                            className="tooltip-wrapper"
                                            onMouseEnter={() => setShowTooltip(true)}
                                            onMouseLeave={() => setShowTooltip(false)}
                                        >
                                            {showTooltip && !hasSpokenNear[user.profile.id] && (
                                                <div className="tooltip">You must speak to connect.</div>
                                            )}
                                            <button
                                                className="follow-button"
                                                onClick={() => handleFollowButtonClick(user.profile.id)}
                                                disabled={!hasSpokenNear[user.profile.id]}
                                            >
                                                Connect
                                            </button>
                                        </div>
                                    ) : null}
                                </div>
                            );
                        })}
                    </div>
                    {proximityChatUsers.length > maxVisible && (
                        <FontAwesomeIcon icon={faChevronRight} size="2x" className="chevron chevron-right fa-1x" onClick={handleNextClick} />
                    )}
                </>
            ) : (
                <div className="join-voice-chat">
                    <div className="join-title">Join Voice Chat?</div>
                    <div className="join-description">
                        You can earn points by connecting with other TRIBE members within the space.
                    </div>
                    <div className="join-buttons">
                        <button className="yes-button" onClick={handleJoinClick}>Yes</button>
                        <button className="no-button" onClick={handleNoClick}>No</button>
                    </div>
                </div>
            )}
        </div>
    ); */
};

export default ProximityChat;
