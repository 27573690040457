/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  Text,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { listCreators, listInventoryItems } from "../graphql/queries";
import { createProduct, updateInventoryItem } from "../graphql/mutations";
const client = generateClient();
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function ProductCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    creator: undefined,
    itemName: "",
    stripeProductId: "",
    description: "",
    price: "",
    tribePointsPrice: "",
    imageUrl: "",
    stock: "",
    inventoryItems: [],
    productType: "",
    glbUrl: "",
    creatorProductsId: undefined,
  };
  const [creator, setCreator] = React.useState(initialValues.creator);
  const [creatorLoading, setCreatorLoading] = React.useState(false);
  const [creatorRecords, setCreatorRecords] = React.useState([]);
  const [itemName, setItemName] = React.useState(initialValues.itemName);
  const [stripeProductId, setStripeProductId] = React.useState(
    initialValues.stripeProductId
  );
  const [description, setDescription] = React.useState(
    initialValues.description
  );
  const [price, setPrice] = React.useState(initialValues.price);
  const [tribePointsPrice, setTribePointsPrice] = React.useState(
    initialValues.tribePointsPrice
  );
  const [imageUrl, setImageUrl] = React.useState(initialValues.imageUrl);
  const [stock, setStock] = React.useState(initialValues.stock);
  const [inventoryItems, setInventoryItems] = React.useState(
    initialValues.inventoryItems
  );
  const [inventoryItemsLoading, setInventoryItemsLoading] =
    React.useState(false);
  const [inventoryItemsRecords, setInventoryItemsRecords] = React.useState([]);
  const [productType, setProductType] = React.useState(
    initialValues.productType
  );
  const [glbUrl, setGlbUrl] = React.useState(initialValues.glbUrl);
  const [creatorProductsId, setCreatorProductsId] = React.useState(
    initialValues.creatorProductsId
  );
  const [creatorProductsIdLoading, setCreatorProductsIdLoading] =
    React.useState(false);
  const [creatorProductsIdRecords, setCreatorProductsIdRecords] =
    React.useState([]);
  const [
    selectedCreatorProductsIdRecords,
    setSelectedCreatorProductsIdRecords,
  ] = React.useState([]);
  const autocompleteLength = 10;
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setCreator(initialValues.creator);
    setCurrentCreatorValue(undefined);
    setCurrentCreatorDisplayValue("");
    setItemName(initialValues.itemName);
    setStripeProductId(initialValues.stripeProductId);
    setDescription(initialValues.description);
    setPrice(initialValues.price);
    setTribePointsPrice(initialValues.tribePointsPrice);
    setImageUrl(initialValues.imageUrl);
    setStock(initialValues.stock);
    setInventoryItems(initialValues.inventoryItems);
    setCurrentInventoryItemsValue(undefined);
    setCurrentInventoryItemsDisplayValue("");
    setProductType(initialValues.productType);
    setGlbUrl(initialValues.glbUrl);
    setCreatorProductsId(initialValues.creatorProductsId);
    setCurrentCreatorProductsIdValue(undefined);
    setCurrentCreatorProductsIdDisplayValue("");
    setErrors({});
  };
  const [currentCreatorDisplayValue, setCurrentCreatorDisplayValue] =
    React.useState("");
  const [currentCreatorValue, setCurrentCreatorValue] =
    React.useState(undefined);
  const creatorRef = React.createRef();
  const [
    currentInventoryItemsDisplayValue,
    setCurrentInventoryItemsDisplayValue,
  ] = React.useState("");
  const [currentInventoryItemsValue, setCurrentInventoryItemsValue] =
    React.useState(undefined);
  const inventoryItemsRef = React.createRef();
  const [
    currentCreatorProductsIdDisplayValue,
    setCurrentCreatorProductsIdDisplayValue,
  ] = React.useState("");
  const [currentCreatorProductsIdValue, setCurrentCreatorProductsIdValue] =
    React.useState(undefined);
  const creatorProductsIdRef = React.createRef();
  const getIDValue = {
    creator: (r) => JSON.stringify({ id: r?.id }),
    inventoryItems: (r) => JSON.stringify({ id: r?.id }),
  };
  const creatorIdSet = new Set(
    Array.isArray(creator)
      ? creator.map((r) => getIDValue.creator?.(r))
      : getIDValue.creator?.(creator)
  );
  const inventoryItemsIdSet = new Set(
    Array.isArray(inventoryItems)
      ? inventoryItems.map((r) => getIDValue.inventoryItems?.(r))
      : getIDValue.inventoryItems?.(inventoryItems)
  );
  const getDisplayValue = {
    creator: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
    inventoryItems: (r) => `${r?.status ? r?.status + " - " : ""}${r?.id}`,
    creatorProductsId: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
  };
  const validations = {
    creator: [],
    itemName: [{ type: "Required" }],
    stripeProductId: [],
    description: [],
    price: [],
    tribePointsPrice: [],
    imageUrl: [],
    stock: [],
    inventoryItems: [],
    productType: [],
    glbUrl: [],
    creatorProductsId: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const fetchCreatorRecords = async (value) => {
    setCreatorLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ name: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listCreators.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listCreators?.items;
      var loaded = result.filter(
        (item) => !creatorIdSet.has(getIDValue.creator?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setCreatorRecords(newOptions.slice(0, autocompleteLength));
    setCreatorLoading(false);
  };
  const fetchInventoryItemsRecords = async (value) => {
    setInventoryItemsLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ status: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listInventoryItems.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listInventoryItems?.items;
      var loaded = result.filter(
        (item) => !inventoryItemsIdSet.has(getIDValue.inventoryItems?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setInventoryItemsRecords(newOptions.slice(0, autocompleteLength));
    setInventoryItemsLoading(false);
  };
  const fetchCreatorProductsIdRecords = async (value) => {
    setCreatorProductsIdLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ name: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listCreators.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listCreators?.items;
      var loaded = result.filter((item) => creatorProductsId !== item.id);
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setCreatorProductsIdRecords(newOptions.slice(0, autocompleteLength));
    setCreatorProductsIdLoading(false);
  };
  React.useEffect(() => {
    fetchCreatorRecords("");
    fetchInventoryItemsRecords("");
    fetchCreatorProductsIdRecords("");
  }, []);
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          creator,
          itemName,
          stripeProductId,
          description,
          price,
          tribePointsPrice,
          imageUrl,
          stock,
          inventoryItems,
          productType,
          glbUrl,
          creatorProductsId,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(
                    fieldName,
                    item,
                    getDisplayValue[fieldName]
                  )
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(
                fieldName,
                modelFields[fieldName],
                getDisplayValue[fieldName]
              )
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const modelFieldsToSave = {
            creatorID: modelFields?.creator?.id,
            itemName: modelFields.itemName,
            stripeProductId: modelFields.stripeProductId,
            description: modelFields.description,
            price: modelFields.price,
            tribePointsPrice: modelFields.tribePointsPrice,
            imageUrl: modelFields.imageUrl,
            stock: modelFields.stock,
            productType: modelFields.productType,
            glbUrl: modelFields.glbUrl,
            creatorProductsId: modelFields.creatorProductsId,
          };
          const product = (
            await client.graphql({
              query: createProduct.replaceAll("__typename", ""),
              variables: {
                input: {
                  ...modelFieldsToSave,
                },
              },
            })
          )?.data?.createProduct;
          const promises = [];
          promises.push(
            ...inventoryItems.reduce((promises, original) => {
              promises.push(
                client.graphql({
                  query: updateInventoryItem.replaceAll("__typename", ""),
                  variables: {
                    input: {
                      id: original.id,
                    },
                  },
                })
              );
              return promises;
            }, [])
          );
          await Promise.all(promises);
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "ProductCreateForm")}
      {...rest}
    >
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              creator: value,
              itemName,
              stripeProductId,
              description,
              price,
              tribePointsPrice,
              imageUrl,
              stock,
              inventoryItems,
              productType,
              glbUrl,
              creatorProductsId,
            };
            const result = onChange(modelFields);
            value = result?.creator ?? value;
          }
          setCreator(value);
          setCurrentCreatorValue(undefined);
          setCurrentCreatorDisplayValue("");
        }}
        currentFieldValue={currentCreatorValue}
        label={"Creator"}
        items={creator ? [creator] : []}
        hasError={errors?.creator?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("creator", currentCreatorValue)
        }
        errorMessage={errors?.creator?.errorMessage}
        getBadgeText={getDisplayValue.creator}
        setFieldValue={(model) => {
          setCurrentCreatorDisplayValue(
            model ? getDisplayValue.creator(model) : ""
          );
          setCurrentCreatorValue(model);
        }}
        inputFieldRef={creatorRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Creator"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Creator"
          value={currentCreatorDisplayValue}
          options={creatorRecords
            .filter((r) => !creatorIdSet.has(getIDValue.creator?.(r)))
            .map((r) => ({
              id: getIDValue.creator?.(r),
              label: getDisplayValue.creator?.(r),
            }))}
          isLoading={creatorLoading}
          onSelect={({ id, label }) => {
            setCurrentCreatorValue(
              creatorRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCreatorDisplayValue(label);
            runValidationTasks("creator", label);
          }}
          onClear={() => {
            setCurrentCreatorDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchCreatorRecords(value);
            if (errors.creator?.hasError) {
              runValidationTasks("creator", value);
            }
            setCurrentCreatorDisplayValue(value);
            setCurrentCreatorValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("creator", currentCreatorDisplayValue)
          }
          errorMessage={errors.creator?.errorMessage}
          hasError={errors.creator?.hasError}
          ref={creatorRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "creator")}
        ></Autocomplete>
      </ArrayField>
      <TextField
        label="Item name"
        isRequired={true}
        isReadOnly={false}
        value={itemName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              creator,
              itemName: value,
              stripeProductId,
              description,
              price,
              tribePointsPrice,
              imageUrl,
              stock,
              inventoryItems,
              productType,
              glbUrl,
              creatorProductsId,
            };
            const result = onChange(modelFields);
            value = result?.itemName ?? value;
          }
          if (errors.itemName?.hasError) {
            runValidationTasks("itemName", value);
          }
          setItemName(value);
        }}
        onBlur={() => runValidationTasks("itemName", itemName)}
        errorMessage={errors.itemName?.errorMessage}
        hasError={errors.itemName?.hasError}
        {...getOverrideProps(overrides, "itemName")}
      ></TextField>
      <TextField
        label="Stripe product id"
        isRequired={false}
        isReadOnly={false}
        value={stripeProductId}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              creator,
              itemName,
              stripeProductId: value,
              description,
              price,
              tribePointsPrice,
              imageUrl,
              stock,
              inventoryItems,
              productType,
              glbUrl,
              creatorProductsId,
            };
            const result = onChange(modelFields);
            value = result?.stripeProductId ?? value;
          }
          if (errors.stripeProductId?.hasError) {
            runValidationTasks("stripeProductId", value);
          }
          setStripeProductId(value);
        }}
        onBlur={() => runValidationTasks("stripeProductId", stripeProductId)}
        errorMessage={errors.stripeProductId?.errorMessage}
        hasError={errors.stripeProductId?.hasError}
        {...getOverrideProps(overrides, "stripeProductId")}
      ></TextField>
      <TextField
        label="Description"
        isRequired={false}
        isReadOnly={false}
        value={description}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              creator,
              itemName,
              stripeProductId,
              description: value,
              price,
              tribePointsPrice,
              imageUrl,
              stock,
              inventoryItems,
              productType,
              glbUrl,
              creatorProductsId,
            };
            const result = onChange(modelFields);
            value = result?.description ?? value;
          }
          if (errors.description?.hasError) {
            runValidationTasks("description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("description", description)}
        errorMessage={errors.description?.errorMessage}
        hasError={errors.description?.hasError}
        {...getOverrideProps(overrides, "description")}
      ></TextField>
      <TextField
        label="Price"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={price}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              creator,
              itemName,
              stripeProductId,
              description,
              price: value,
              tribePointsPrice,
              imageUrl,
              stock,
              inventoryItems,
              productType,
              glbUrl,
              creatorProductsId,
            };
            const result = onChange(modelFields);
            value = result?.price ?? value;
          }
          if (errors.price?.hasError) {
            runValidationTasks("price", value);
          }
          setPrice(value);
        }}
        onBlur={() => runValidationTasks("price", price)}
        errorMessage={errors.price?.errorMessage}
        hasError={errors.price?.hasError}
        {...getOverrideProps(overrides, "price")}
      ></TextField>
      <TextField
        label="Tribe points price"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={tribePointsPrice}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              creator,
              itemName,
              stripeProductId,
              description,
              price,
              tribePointsPrice: value,
              imageUrl,
              stock,
              inventoryItems,
              productType,
              glbUrl,
              creatorProductsId,
            };
            const result = onChange(modelFields);
            value = result?.tribePointsPrice ?? value;
          }
          if (errors.tribePointsPrice?.hasError) {
            runValidationTasks("tribePointsPrice", value);
          }
          setTribePointsPrice(value);
        }}
        onBlur={() => runValidationTasks("tribePointsPrice", tribePointsPrice)}
        errorMessage={errors.tribePointsPrice?.errorMessage}
        hasError={errors.tribePointsPrice?.hasError}
        {...getOverrideProps(overrides, "tribePointsPrice")}
      ></TextField>
      <TextField
        label="Image url"
        isRequired={false}
        isReadOnly={false}
        value={imageUrl}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              creator,
              itemName,
              stripeProductId,
              description,
              price,
              tribePointsPrice,
              imageUrl: value,
              stock,
              inventoryItems,
              productType,
              glbUrl,
              creatorProductsId,
            };
            const result = onChange(modelFields);
            value = result?.imageUrl ?? value;
          }
          if (errors.imageUrl?.hasError) {
            runValidationTasks("imageUrl", value);
          }
          setImageUrl(value);
        }}
        onBlur={() => runValidationTasks("imageUrl", imageUrl)}
        errorMessage={errors.imageUrl?.errorMessage}
        hasError={errors.imageUrl?.hasError}
        {...getOverrideProps(overrides, "imageUrl")}
      ></TextField>
      <TextField
        label="Stock"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={stock}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              creator,
              itemName,
              stripeProductId,
              description,
              price,
              tribePointsPrice,
              imageUrl,
              stock: value,
              inventoryItems,
              productType,
              glbUrl,
              creatorProductsId,
            };
            const result = onChange(modelFields);
            value = result?.stock ?? value;
          }
          if (errors.stock?.hasError) {
            runValidationTasks("stock", value);
          }
          setStock(value);
        }}
        onBlur={() => runValidationTasks("stock", stock)}
        errorMessage={errors.stock?.errorMessage}
        hasError={errors.stock?.hasError}
        {...getOverrideProps(overrides, "stock")}
      ></TextField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              creator,
              itemName,
              stripeProductId,
              description,
              price,
              tribePointsPrice,
              imageUrl,
              stock,
              inventoryItems: values,
              productType,
              glbUrl,
              creatorProductsId,
            };
            const result = onChange(modelFields);
            values = result?.inventoryItems ?? values;
          }
          setInventoryItems(values);
          setCurrentInventoryItemsValue(undefined);
          setCurrentInventoryItemsDisplayValue("");
        }}
        currentFieldValue={currentInventoryItemsValue}
        label={"Inventory items"}
        items={inventoryItems}
        hasError={errors?.inventoryItems?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("inventoryItems", currentInventoryItemsValue)
        }
        errorMessage={errors?.inventoryItems?.errorMessage}
        getBadgeText={getDisplayValue.inventoryItems}
        setFieldValue={(model) => {
          setCurrentInventoryItemsDisplayValue(
            model ? getDisplayValue.inventoryItems(model) : ""
          );
          setCurrentInventoryItemsValue(model);
        }}
        inputFieldRef={inventoryItemsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Inventory items"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search InventoryItem"
          value={currentInventoryItemsDisplayValue}
          options={inventoryItemsRecords.map((r) => ({
            id: getIDValue.inventoryItems?.(r),
            label: getDisplayValue.inventoryItems?.(r),
          }))}
          isLoading={inventoryItemsLoading}
          onSelect={({ id, label }) => {
            setCurrentInventoryItemsValue(
              inventoryItemsRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentInventoryItemsDisplayValue(label);
            runValidationTasks("inventoryItems", label);
          }}
          onClear={() => {
            setCurrentInventoryItemsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchInventoryItemsRecords(value);
            if (errors.inventoryItems?.hasError) {
              runValidationTasks("inventoryItems", value);
            }
            setCurrentInventoryItemsDisplayValue(value);
            setCurrentInventoryItemsValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks(
              "inventoryItems",
              currentInventoryItemsDisplayValue
            )
          }
          errorMessage={errors.inventoryItems?.errorMessage}
          hasError={errors.inventoryItems?.hasError}
          ref={inventoryItemsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "inventoryItems")}
        ></Autocomplete>
      </ArrayField>
      <TextField
        label="Product type"
        isRequired={false}
        isReadOnly={false}
        value={productType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              creator,
              itemName,
              stripeProductId,
              description,
              price,
              tribePointsPrice,
              imageUrl,
              stock,
              inventoryItems,
              productType: value,
              glbUrl,
              creatorProductsId,
            };
            const result = onChange(modelFields);
            value = result?.productType ?? value;
          }
          if (errors.productType?.hasError) {
            runValidationTasks("productType", value);
          }
          setProductType(value);
        }}
        onBlur={() => runValidationTasks("productType", productType)}
        errorMessage={errors.productType?.errorMessage}
        hasError={errors.productType?.hasError}
        {...getOverrideProps(overrides, "productType")}
      ></TextField>
      <TextField
        label="Glb url"
        isRequired={false}
        isReadOnly={false}
        value={glbUrl}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              creator,
              itemName,
              stripeProductId,
              description,
              price,
              tribePointsPrice,
              imageUrl,
              stock,
              inventoryItems,
              productType,
              glbUrl: value,
              creatorProductsId,
            };
            const result = onChange(modelFields);
            value = result?.glbUrl ?? value;
          }
          if (errors.glbUrl?.hasError) {
            runValidationTasks("glbUrl", value);
          }
          setGlbUrl(value);
        }}
        onBlur={() => runValidationTasks("glbUrl", glbUrl)}
        errorMessage={errors.glbUrl?.errorMessage}
        hasError={errors.glbUrl?.hasError}
        {...getOverrideProps(overrides, "glbUrl")}
      ></TextField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              creator,
              itemName,
              stripeProductId,
              description,
              price,
              tribePointsPrice,
              imageUrl,
              stock,
              inventoryItems,
              productType,
              glbUrl,
              creatorProductsId: value,
            };
            const result = onChange(modelFields);
            value = result?.creatorProductsId ?? value;
          }
          setCreatorProductsId(value);
          setCurrentCreatorProductsIdValue(undefined);
        }}
        currentFieldValue={currentCreatorProductsIdValue}
        label={"Creator products id"}
        items={creatorProductsId ? [creatorProductsId] : []}
        hasError={errors?.creatorProductsId?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "creatorProductsId",
            currentCreatorProductsIdValue
          )
        }
        errorMessage={errors?.creatorProductsId?.errorMessage}
        getBadgeText={(value) =>
          value
            ? getDisplayValue.creatorProductsId(
                creatorProductsIdRecords.find((r) => r.id === value) ??
                  selectedCreatorProductsIdRecords.find((r) => r.id === value)
              )
            : ""
        }
        setFieldValue={(value) => {
          setCurrentCreatorProductsIdDisplayValue(
            value
              ? getDisplayValue.creatorProductsId(
                  creatorProductsIdRecords.find((r) => r.id === value) ??
                    selectedCreatorProductsIdRecords.find((r) => r.id === value)
                )
              : ""
          );
          setCurrentCreatorProductsIdValue(value);
          const selectedRecord = creatorProductsIdRecords.find(
            (r) => r.id === value
          );
          if (selectedRecord) {
            setSelectedCreatorProductsIdRecords([selectedRecord]);
          }
        }}
        inputFieldRef={creatorProductsIdRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Creator products id"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Creator"
          value={currentCreatorProductsIdDisplayValue}
          options={creatorProductsIdRecords
            .filter(
              (r, i, arr) =>
                arr.findIndex((member) => member?.id === r?.id) === i
            )
            .map((r) => ({
              id: r?.id,
              label: getDisplayValue.creatorProductsId?.(r),
            }))}
          isLoading={creatorProductsIdLoading}
          onSelect={({ id, label }) => {
            setCurrentCreatorProductsIdValue(id);
            setCurrentCreatorProductsIdDisplayValue(label);
            runValidationTasks("creatorProductsId", label);
          }}
          onClear={() => {
            setCurrentCreatorProductsIdDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchCreatorProductsIdRecords(value);
            if (errors.creatorProductsId?.hasError) {
              runValidationTasks("creatorProductsId", value);
            }
            setCurrentCreatorProductsIdDisplayValue(value);
            setCurrentCreatorProductsIdValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks(
              "creatorProductsId",
              currentCreatorProductsIdValue
            )
          }
          errorMessage={errors.creatorProductsId?.errorMessage}
          hasError={errors.creatorProductsId?.hasError}
          ref={creatorProductsIdRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "creatorProductsId")}
        ></Autocomplete>
      </ArrayField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
