import { Flex } from '@aws-amplify/ui-react';
import { Authenticator } from "@aws-amplify/ui-react";
import triberspaceLogo from "../assets/TriberspaceLogo.svg"

const AuthenticatorWrapper = ({ authLoading }) => {
  const styles = {
    logo: {
      width: '216px', // Adjust to fit your design
      height: '30px',   // Adjust to fit your design
    },
  };
  return (
    <Flex direction="column" backgroundColor="#1f1f1f" alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
      <img
            src={triberspaceLogo}
            alt="TriberSpace Logo"
            style={styles.logo}
          />
      <Flex 
        direction="column" 
        alignItems="center" 
        justifyContent="center" 
        maxWidth="500px"
        style={{ width: '80%', backgroundColor: '#1f1f1f', borderRadius: '12px' }}
      >
        {authLoading ? (
          <div style={{ width: '100%', fontSize: '20px', color: 'white', textAlign: 'center' }}>Loading...</div> // Your loading indicator here
        ) : (
          <Authenticator style={{ width: '100%' }}/>
        )}
      </Flex>
    </Flex>
  );
};

export default AuthenticatorWrapper;
