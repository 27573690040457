import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, Flex } from '@aws-amplify/ui-react';
import { updateProfile } from '../../graphql/mutations';
import { generateClient } from 'aws-amplify/api';
import { GlobalContext } from '../../tspace_components/contexts/GlobalStore';
import { useNavigate } from 'react-router-dom';
import './ProfilePage.css';

const client = generateClient();

const ProfilePage = () => {
  const { currentUserProfile, setCurrentUserProfile } = useContext(GlobalContext);
  let navigate = useNavigate();

  const [profileData, setProfileData] = useState({
    username: '',
    name: '',
    bio: '',
    instagram: '',
    twitter: '',
    baseAvatarColor: '',
  });

  const [initialProfileData, setInitialProfileData] = useState({ ...profileData });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (currentUserProfile) {
      const newProfileData = {
        username: currentUserProfile.username,
        name: currentUserProfile.name,
        bio: currentUserProfile.bio,
        instagram: currentUserProfile.instagram,
        twitter: currentUserProfile.twitter,
        baseAvatarColor: currentUserProfile.baseAvatarColor,
      };
      setProfileData(newProfileData);
      setInitialProfileData(newProfileData);
    }
  }, [currentUserProfile]);

  const handleEditClick = () => {
    if (isEditing) {
      // If canceling the edit, reset to initial data
      setProfileData(initialProfileData);
    }
    setIsEditing(!isEditing);
  };

  const handleAvatarClick = () => {
    navigate("/Avatar")
  };

  const handleSaveClick = async () => {
    try {
      const updatedProfile = {
        ...profileData,
      };

      // Check if any changes were made before sending the update request
      if (JSON.stringify(updatedProfile) !== JSON.stringify(initialProfileData)) {
        const result = await client.graphql({
          query: updateProfile,
          variables: {
            input: {
              id: currentUserProfile.id,
              ...updatedProfile,
            },
          },
        });

        setCurrentUserProfile(result.data.updateProfile);
        setInitialProfileData(profileData); // Update the initial data to the new saved data
      }
      
      setIsEditing(false); // Always exit edit mode after clicking Done
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleChange = (field) => (e) => {
    setProfileData({
      ...profileData,
      [field]: e.target.value,
    });
  };

  const textFieldStyles = {
    color: 'white',
    borderRadius: '12px',
    padding: '16px',
    borderColor: 'rgba(255, 255, 255, 0.2)', // Ensures consistent border color
  };

  return (
    <div className="profile-page">
      <div className="profile-header">
        <div className='edit-button-container'>
          <Button 
            onClick={isEditing ? handleSaveClick : handleEditClick} 
            className="edit-button"
            style={{ color: 'white', borderRadius: '8px' }}
          >
            {isEditing ? 'Done' : 'Edit'}
          </Button>
        </div>
        <div className="profile-info">
          <div className="profile-page-image-placeholder"></div>
          <div className="profile-texts">
            {isEditing ? (
              <TextField
                label=""
                value={profileData.name}
                onChange={handleChange('name')}
                className="profile-name"
                hasError={false}
                style={textFieldStyles}
              />
            ) : (
              <div className="profile-name-display">{profileData.name}</div>
            )}
            {isEditing ? (
              <TextField
                label=""
                value={profileData.username}
                onChange={handleChange('username')}
                className="profile-username"
                hasError={false}
                style={textFieldStyles}
              />
            ) : (
              <div className="profile-username-display">@{profileData.username}</div>
            )}
          </div>
        </div>
      </div>

      <div className="profile-section">
        <h3>Bio</h3>
        <TextField
          label=""
          isReadOnly={!isEditing}
          value={profileData.bio}
          onChange={handleChange('bio')}
          className="profile-bio"
          multiline
          rows={5}
          hasError={false}
          style={textFieldStyles}
        />
      </div>

      <div className="profile-section">
        <Flex direction="row" justifyContent="space-between">
          <div className="profile-subsection">
            <h3>Instagram</h3>
            <TextField
              label=""
              isReadOnly={!isEditing}
              value={profileData.instagram}
              onChange={handleChange('instagram')}
              className="profile-instagram"
              hasError={false}
              style={textFieldStyles}
            />
          </div>
          <div className="profile-subsection">
            <h3>Twitter</h3>
            <TextField
              label=""
              isReadOnly={!isEditing}
              value={profileData.twitter}
              onChange={handleChange('twitter')}
              className="profile-twitter"
              hasError={false}
              style={textFieldStyles}
            />
          </div>
        </Flex>
      </div>
    </div>
  );
};

export default ProfilePage;
