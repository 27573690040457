import React from 'react';
import { SocketProvider } from './SocketContext';
import { LiveKitProvider } from './LiveKitContext';
import { UIOverlayContextProvider } from './UIOverlayContext';
import { VideoStateProvider } from './VideoStateContext';
import { AudioStateProvider } from './AudioStateContext';
import { AvatarProvider } from './AvatarContext';
import { PlayerPositionsProvider } from './PlayerPositionsProvider';
import { DialogueProvider } from './DialogueContext';


const AllProviders = ({ children }) => {
  return (
    <SocketProvider>
      <UIOverlayContextProvider>
        <VideoStateProvider>
          <AudioStateProvider>
            <PlayerPositionsProvider>
              <LiveKitProvider>
                  <DialogueProvider>
                    {children}
                  </DialogueProvider>
              </LiveKitProvider>
            </PlayerPositionsProvider>
          </AudioStateProvider>
        </VideoStateProvider>
      </UIOverlayContextProvider>
    </SocketProvider>
  );
};

export default AllProviders;
