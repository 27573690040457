import { createContext, useContext, useState, useEffect } from 'react';
import { Client } from 'colyseus.js';
import { GlobalContext } from './GlobalStore';

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const [client, setClient] = useState(null);
  const [room, setRoom] = useState(null);

  const { fetchUserProfile } = useContext(GlobalContext);
  const [userProfile, setUserProfile] = useState(null);
  const [hasAttemptedJoin, setHasAttemptedJoin] = useState(false);
  const { addProximityChatUser, isLoggedIn } = useContext(GlobalContext);

  const guestUserProfile = {
    id: "guest-id",
    username: "guest",
    name: "Guest User",
    instagram: null,
    twitter: null,
    Avatar: null,
    bio: null,
    baseAvatarColor: "#ffffff",
    followers: {
      nextToken: null,
      __typename: "ModelFollowConnection"
    },
    following: {
      nextToken: null,
      __typename: "ModelFollowConnection"
    },
    connections: {
      nextToken: null,
      __typename: "ModelConnectionConnection"
    },
    pointsRecords: {
      nextToken: null,
      __typename: "ModelPointsConnection"
    },
    totalPoints: 0,
    createdAt: null,
    updatedAt: null,
    profileAvatarId: null,
    owner: null,
    __typename: "Profile"
  };

  // Effect hook to fetch user profile data on component mount
  useEffect(() => {
    if (isLoggedIn === null) return; // Wait until isLoggedIn is determined
    const getUserProfile = async () => {
      const profile = await fetchUserProfile();
      setUserProfile(profile);
    };
    if (isLoggedIn) {
      getUserProfile();
    } else {
      setUserProfile(guestUserProfile);
      console.log("User profile", userProfile);
    }
  }, [fetchUserProfile, isLoggedIn]);

  useEffect(() => {
    const endpoint = process.env.REACT_APP_COLYSEUS_SERVER_URL || 'ws://localhost:3001';
    const newClient = new Client(endpoint);
    setClient(newClient);
  }, []);

  useEffect(() => {
    const joinOrCreateRoom = async () => {
      if (!client || !userProfile || hasAttemptedJoin) return;
      
      setHasAttemptedJoin(true); // Prevent future executions
      console.log("has attempted to join");
  
      const domain = window.location.hostname;
      const token = localStorage.getItem('token');  // Fetch the JWT token from localStorage
  
      try {
        const room = await client.joinOrCreate('my_room', { domain, token, userProfile });  // Send the JWT token here
        setRoom(room);
        console.log("Local session ID:", room.sessionId); // Log or store the session ID
        const roomId = room.sessionId;
        const username = userProfile.username;
        console.log("user profile", userProfile);
        addProximityChatUser({ id: roomId, userName: username, profile: userProfile });
      } catch (error) {
        console.error(error);
      }

    };
  
    joinOrCreateRoom();
  }, [client, userProfile, hasAttemptedJoin]);

  return <SocketContext.Provider value={{ client, room }}>{children}</SocketContext.Provider>;
};