import React, { useState, useEffect, useContext } from 'react';
import { CSSTransition } from 'react-transition-group'; // Import CSSTransition
import { useSocket } from '../contexts/SocketContext';
import { faTimes, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDialogue } from '../contexts/DialogueContext';
import { GlobalContext } from '../contexts/GlobalStore';
import './TextChat.css'; // Import the CSS file for transitions

const TextChat = ({ isVisible }) => {
  const { room } = useSocket();
  const [message, setMessage] = useState('');
  const { setTextChatOpen, isChatUIOpen, setChatUIOpen, setLastMessage } = useDialogue();
  const { isMobileView, hasSpokenNear, setHasSpokenNear, proximityChatUsers } = useContext(GlobalContext);

  const sendMessage = () => {
    if (message.trim()) {
      room.send('chatMessage', message);
      setLastMessage(message); 
      setMessage(''); 

      const updatedHasSpokenNear = { ...hasSpokenNear };
      proximityChatUsers.forEach(user => {
        if (!updatedHasSpokenNear[user.profile.id]) {
          updatedHasSpokenNear[user.profile.id] = true;
        }
      });
      setHasSpokenNear(updatedHasSpokenNear);

      setTimeout(() => {
        setLastMessage(''); 
      }, 5000);
    }
  };

  const closeChat = () => {
    setTextChatOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  useEffect(() => {
    if (isVisible) {
      setChatUIOpen(false); 
    }
  }, [isVisible, setChatUIOpen]);

  return (
    <CSSTransition
      in={isVisible}
      timeout={200}
      classNames="fade"
      unmountOnExit
    >
      <div style={{
        position: 'fixed',
        bottom: '44px',
        left: isMobileView ? 'auto' : '50%',
        right: isMobileView ? '12px' : 'auto',
        transform: isMobileView ? 'none' : 'translateX(-50%)',
        zIndex: 1000,
        width: isMobileView ? '60%' : 'calc(100% - 24px)',
        maxWidth: '500px',
        backgroundColor: 'rgba(31,31,31, 0.7)',
        borderRadius: '30px',
        backdropFilter: 'blur(10px)',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
      }}>
        <div style={{
          cursor: 'pointer',
          display: isMobileView ? 'none' : 'flex', 
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#333',
          opacity: '80%',
          borderRadius: '50%',
          backdropFilter: 'blur(10px)',
          width: '48px',
          height: '48px',
          marginRight: '10px',
        }} onClick={closeChat}>
          <FontAwesomeIcon icon={faTimes} size="lg" color="white" />
        </div>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Send a message"
          onKeyDown={handleKeyDown}
          style={{
            flex: 1,
            flexShrink: 1, 
            padding: '10px 16px',
            fontSize: '16px',
            minWidth: '100px',
            borderRadius: '30px',
            opacity: '80%',
            backdropFilter: 'blur(10px)',
            border: 'none',
            backgroundColor: '#333',
            color: 'white',
            outline: 'none',
          }}
        />
        <div
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#333',
            borderRadius: '50%',
            width: '48px',
            height: '48px',
            marginLeft: '10px',
          }}
          onClick={sendMessage}
        >
          <FontAwesomeIcon icon={faPaperPlane} size="lg" color="white" />
        </div>
      </div>
    </CSSTransition>
  );
};

export default TextChat;
