/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  Text,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getConnection, getProfile, listProfiles } from "../graphql/queries";
import { updateConnection } from "../graphql/mutations";
const client = generateClient();
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function ConnectionUpdateForm(props) {
  const {
    id: idProp,
    connection: connectionModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    user1: undefined,
    user2: undefined,
    createdAt: "",
    profileConnectionsId: undefined,
  };
  const [user1, setUser1] = React.useState(initialValues.user1);
  const [user1Loading, setUser1Loading] = React.useState(false);
  const [user1Records, setUser1Records] = React.useState([]);
  const [user2, setUser2] = React.useState(initialValues.user2);
  const [user2Loading, setUser2Loading] = React.useState(false);
  const [user2Records, setUser2Records] = React.useState([]);
  const [createdAt, setCreatedAt] = React.useState(initialValues.createdAt);
  const [profileConnectionsId, setProfileConnectionsId] = React.useState(
    initialValues.profileConnectionsId
  );
  const [profileConnectionsIdLoading, setProfileConnectionsIdLoading] =
    React.useState(false);
  const [profileConnectionsIdRecords, setProfileConnectionsIdRecords] =
    React.useState([]);
  const [
    selectedProfileConnectionsIdRecords,
    setSelectedProfileConnectionsIdRecords,
  ] = React.useState([]);
  const autocompleteLength = 10;
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = connectionRecord
      ? {
          ...initialValues,
          ...connectionRecord,
          user1,
          user2,
          profileConnectionsId,
        }
      : initialValues;
    setUser1(cleanValues.user1);
    setCurrentUser1Value(undefined);
    setCurrentUser1DisplayValue("");
    setUser2(cleanValues.user2);
    setCurrentUser2Value(undefined);
    setCurrentUser2DisplayValue("");
    setCreatedAt(cleanValues.createdAt);
    setProfileConnectionsId(cleanValues.profileConnectionsId);
    setCurrentProfileConnectionsIdValue(undefined);
    setCurrentProfileConnectionsIdDisplayValue("");
    setErrors({});
  };
  const [connectionRecord, setConnectionRecord] =
    React.useState(connectionModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getConnection.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getConnection
        : connectionModelProp;
      const user1Record = record ? await record.user1 : undefined;
      setUser1(user1Record);
      const user2Record = record ? await record.user2 : undefined;
      setUser2(user2Record);
      const profileConnectionsIdRecord = record
        ? record.profileConnectionsId
        : undefined;
      const profileRecord = profileConnectionsIdRecord
        ? (
            await client.graphql({
              query: getProfile.replaceAll("__typename", ""),
              variables: { id: profileConnectionsIdRecord },
            })
          )?.data?.getProfile
        : undefined;
      setProfileConnectionsId(profileConnectionsIdRecord);
      setSelectedProfileConnectionsIdRecords([profileRecord]);
      setConnectionRecord(record);
    };
    queryData();
  }, [idProp, connectionModelProp]);
  React.useEffect(resetStateValues, [
    connectionRecord,
    user1,
    user2,
    profileConnectionsId,
  ]);
  const [currentUser1DisplayValue, setCurrentUser1DisplayValue] =
    React.useState("");
  const [currentUser1Value, setCurrentUser1Value] = React.useState(undefined);
  const user1Ref = React.createRef();
  const [currentUser2DisplayValue, setCurrentUser2DisplayValue] =
    React.useState("");
  const [currentUser2Value, setCurrentUser2Value] = React.useState(undefined);
  const user2Ref = React.createRef();
  const [
    currentProfileConnectionsIdDisplayValue,
    setCurrentProfileConnectionsIdDisplayValue,
  ] = React.useState("");
  const [
    currentProfileConnectionsIdValue,
    setCurrentProfileConnectionsIdValue,
  ] = React.useState(undefined);
  const profileConnectionsIdRef = React.createRef();
  const getIDValue = {
    user1: (r) => JSON.stringify({ id: r?.id }),
    user2: (r) => JSON.stringify({ id: r?.id }),
  };
  const user1IdSet = new Set(
    Array.isArray(user1)
      ? user1.map((r) => getIDValue.user1?.(r))
      : getIDValue.user1?.(user1)
  );
  const user2IdSet = new Set(
    Array.isArray(user2)
      ? user2.map((r) => getIDValue.user2?.(r))
      : getIDValue.user2?.(user2)
  );
  const getDisplayValue = {
    user1: (r) => `${r?.username ? r?.username + " - " : ""}${r?.id}`,
    user2: (r) => `${r?.username ? r?.username + " - " : ""}${r?.id}`,
    profileConnectionsId: (r) =>
      `${r?.username ? r?.username + " - " : ""}${r?.id}`,
  };
  const validations = {
    user1: [],
    user2: [],
    createdAt: [],
    profileConnectionsId: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const convertToLocal = (date) => {
    const df = new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      calendar: "iso8601",
      numberingSystem: "latn",
      hourCycle: "h23",
    });
    const parts = df.formatToParts(date).reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
    return `${parts.year}-${parts.month}-${parts.day}T${parts.hour}:${parts.minute}`;
  };
  const fetchUser1Records = async (value) => {
    setUser1Loading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ username: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listProfiles.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listProfiles?.items;
      var loaded = result.filter(
        (item) => !user1IdSet.has(getIDValue.user1?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setUser1Records(newOptions.slice(0, autocompleteLength));
    setUser1Loading(false);
  };
  const fetchUser2Records = async (value) => {
    setUser2Loading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ username: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listProfiles.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listProfiles?.items;
      var loaded = result.filter(
        (item) => !user2IdSet.has(getIDValue.user2?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setUser2Records(newOptions.slice(0, autocompleteLength));
    setUser2Loading(false);
  };
  const fetchProfileConnectionsIdRecords = async (value) => {
    setProfileConnectionsIdLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ username: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listProfiles.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listProfiles?.items;
      var loaded = result.filter((item) => profileConnectionsId !== item.id);
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setProfileConnectionsIdRecords(newOptions.slice(0, autocompleteLength));
    setProfileConnectionsIdLoading(false);
  };
  React.useEffect(() => {
    fetchUser1Records("");
    fetchUser2Records("");
    fetchProfileConnectionsIdRecords("");
  }, []);
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          user1: user1 ?? null,
          user2: user2 ?? null,
          createdAt: createdAt ?? null,
          profileConnectionsId: profileConnectionsId ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(
                    fieldName,
                    item,
                    getDisplayValue[fieldName]
                  )
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(
                fieldName,
                modelFields[fieldName],
                getDisplayValue[fieldName]
              )
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const modelFieldsToSave = {
            user1Id: modelFields?.user1?.id ?? null,
            user2Id: modelFields?.user2?.id ?? null,
            createdAt: modelFields.createdAt ?? null,
            profileConnectionsId: modelFields.profileConnectionsId ?? null,
          };
          await client.graphql({
            query: updateConnection.replaceAll("__typename", ""),
            variables: {
              input: {
                id: connectionRecord.id,
                ...modelFieldsToSave,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "ConnectionUpdateForm")}
      {...rest}
    >
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              user1: value,
              user2,
              createdAt,
              profileConnectionsId,
            };
            const result = onChange(modelFields);
            value = result?.user1 ?? value;
          }
          setUser1(value);
          setCurrentUser1Value(undefined);
          setCurrentUser1DisplayValue("");
        }}
        currentFieldValue={currentUser1Value}
        label={"User1"}
        items={user1 ? [user1] : []}
        hasError={errors?.user1?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("user1", currentUser1Value)
        }
        errorMessage={errors?.user1?.errorMessage}
        getBadgeText={getDisplayValue.user1}
        setFieldValue={(model) => {
          setCurrentUser1DisplayValue(
            model ? getDisplayValue.user1(model) : ""
          );
          setCurrentUser1Value(model);
        }}
        inputFieldRef={user1Ref}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="User1"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Profile"
          value={currentUser1DisplayValue}
          options={user1Records
            .filter((r) => !user1IdSet.has(getIDValue.user1?.(r)))
            .map((r) => ({
              id: getIDValue.user1?.(r),
              label: getDisplayValue.user1?.(r),
            }))}
          isLoading={user1Loading}
          onSelect={({ id, label }) => {
            setCurrentUser1Value(
              user1Records.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentUser1DisplayValue(label);
            runValidationTasks("user1", label);
          }}
          onClear={() => {
            setCurrentUser1DisplayValue("");
          }}
          defaultValue={user1}
          onChange={(e) => {
            let { value } = e.target;
            fetchUser1Records(value);
            if (errors.user1?.hasError) {
              runValidationTasks("user1", value);
            }
            setCurrentUser1DisplayValue(value);
            setCurrentUser1Value(undefined);
          }}
          onBlur={() => runValidationTasks("user1", currentUser1DisplayValue)}
          errorMessage={errors.user1?.errorMessage}
          hasError={errors.user1?.hasError}
          ref={user1Ref}
          labelHidden={true}
          {...getOverrideProps(overrides, "user1")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              user1,
              user2: value,
              createdAt,
              profileConnectionsId,
            };
            const result = onChange(modelFields);
            value = result?.user2 ?? value;
          }
          setUser2(value);
          setCurrentUser2Value(undefined);
          setCurrentUser2DisplayValue("");
        }}
        currentFieldValue={currentUser2Value}
        label={"User2"}
        items={user2 ? [user2] : []}
        hasError={errors?.user2?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("user2", currentUser2Value)
        }
        errorMessage={errors?.user2?.errorMessage}
        getBadgeText={getDisplayValue.user2}
        setFieldValue={(model) => {
          setCurrentUser2DisplayValue(
            model ? getDisplayValue.user2(model) : ""
          );
          setCurrentUser2Value(model);
        }}
        inputFieldRef={user2Ref}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="User2"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Profile"
          value={currentUser2DisplayValue}
          options={user2Records
            .filter((r) => !user2IdSet.has(getIDValue.user2?.(r)))
            .map((r) => ({
              id: getIDValue.user2?.(r),
              label: getDisplayValue.user2?.(r),
            }))}
          isLoading={user2Loading}
          onSelect={({ id, label }) => {
            setCurrentUser2Value(
              user2Records.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentUser2DisplayValue(label);
            runValidationTasks("user2", label);
          }}
          onClear={() => {
            setCurrentUser2DisplayValue("");
          }}
          defaultValue={user2}
          onChange={(e) => {
            let { value } = e.target;
            fetchUser2Records(value);
            if (errors.user2?.hasError) {
              runValidationTasks("user2", value);
            }
            setCurrentUser2DisplayValue(value);
            setCurrentUser2Value(undefined);
          }}
          onBlur={() => runValidationTasks("user2", currentUser2DisplayValue)}
          errorMessage={errors.user2?.errorMessage}
          hasError={errors.user2?.hasError}
          ref={user2Ref}
          labelHidden={true}
          {...getOverrideProps(overrides, "user2")}
        ></Autocomplete>
      </ArrayField>
      <TextField
        label="Created at"
        isRequired={false}
        isReadOnly={false}
        type="datetime-local"
        value={createdAt && convertToLocal(new Date(createdAt))}
        onChange={(e) => {
          let value =
            e.target.value === "" ? "" : new Date(e.target.value).toISOString();
          if (onChange) {
            const modelFields = {
              user1,
              user2,
              createdAt: value,
              profileConnectionsId,
            };
            const result = onChange(modelFields);
            value = result?.createdAt ?? value;
          }
          if (errors.createdAt?.hasError) {
            runValidationTasks("createdAt", value);
          }
          setCreatedAt(value);
        }}
        onBlur={() => runValidationTasks("createdAt", createdAt)}
        errorMessage={errors.createdAt?.errorMessage}
        hasError={errors.createdAt?.hasError}
        {...getOverrideProps(overrides, "createdAt")}
      ></TextField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              user1,
              user2,
              createdAt,
              profileConnectionsId: value,
            };
            const result = onChange(modelFields);
            value = result?.profileConnectionsId ?? value;
          }
          setProfileConnectionsId(value);
          setCurrentProfileConnectionsIdValue(undefined);
        }}
        currentFieldValue={currentProfileConnectionsIdValue}
        label={"Profile connections id"}
        items={profileConnectionsId ? [profileConnectionsId] : []}
        hasError={errors?.profileConnectionsId?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "profileConnectionsId",
            currentProfileConnectionsIdValue
          )
        }
        errorMessage={errors?.profileConnectionsId?.errorMessage}
        getBadgeText={(value) =>
          value
            ? getDisplayValue.profileConnectionsId(
                profileConnectionsIdRecords.find((r) => r.id === value) ??
                  selectedProfileConnectionsIdRecords.find(
                    (r) => r.id === value
                  )
              )
            : ""
        }
        setFieldValue={(value) => {
          setCurrentProfileConnectionsIdDisplayValue(
            value
              ? getDisplayValue.profileConnectionsId(
                  profileConnectionsIdRecords.find((r) => r.id === value) ??
                    selectedProfileConnectionsIdRecords.find(
                      (r) => r.id === value
                    )
                )
              : ""
          );
          setCurrentProfileConnectionsIdValue(value);
          const selectedRecord = profileConnectionsIdRecords.find(
            (r) => r.id === value
          );
          if (selectedRecord) {
            setSelectedProfileConnectionsIdRecords([selectedRecord]);
          }
        }}
        inputFieldRef={profileConnectionsIdRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Profile connections id"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Profile"
          value={currentProfileConnectionsIdDisplayValue}
          options={profileConnectionsIdRecords
            .filter(
              (r, i, arr) =>
                arr.findIndex((member) => member?.id === r?.id) === i
            )
            .map((r) => ({
              id: r?.id,
              label: getDisplayValue.profileConnectionsId?.(r),
            }))}
          isLoading={profileConnectionsIdLoading}
          onSelect={({ id, label }) => {
            setCurrentProfileConnectionsIdValue(id);
            setCurrentProfileConnectionsIdDisplayValue(label);
            runValidationTasks("profileConnectionsId", label);
          }}
          onClear={() => {
            setCurrentProfileConnectionsIdDisplayValue("");
          }}
          defaultValue={profileConnectionsId}
          onChange={(e) => {
            let { value } = e.target;
            fetchProfileConnectionsIdRecords(value);
            if (errors.profileConnectionsId?.hasError) {
              runValidationTasks("profileConnectionsId", value);
            }
            setCurrentProfileConnectionsIdDisplayValue(value);
            setCurrentProfileConnectionsIdValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks(
              "profileConnectionsId",
              currentProfileConnectionsIdValue
            )
          }
          errorMessage={errors.profileConnectionsId?.errorMessage}
          hasError={errors.profileConnectionsId?.hasError}
          ref={profileConnectionsIdRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "profileConnectionsId")}
        ></Autocomplete>
      </ArrayField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || connectionModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || connectionModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
