// LiveKitContext.js
import { createContext, useContext, useState, useEffect } from 'react';
import { Room, createLocalAudioTrack, VideoPresets, RoomEvent } from 'livekit-client';
import { useSocket } from '../contexts/SocketContext';
import { AudioListener, PositionalAudio } from 'three';
import { GlobalContext } from './GlobalStore';

export const LiveKitContext = createContext();

export const useLiveKit = () => useContext(LiveKitContext);

export const LiveKitProvider = ({ children }) => {
  const [lkRoom, setLkRoom] = useState(null);
  const [isJoined, setIsJoined] = useState(false);
  const [isMuted, setIsMuted] = useState(false); // Add state to manage mic mute/unmute
  const [error, setError] = useState(null);
  const { room } = useSocket();
  const [audioListener, setAudioListener] = useState(new AudioListener());
  const [positionalAudios, setPositionalAudios] = useState({});
  const [activeSpeakers, setActiveSpeakers] = useState(new Set());
  const { isLoggedIn } = useContext(GlobalContext);

  const connectToLiveKit = async () => {
    if (!room || lkRoom || !isLoggedIn) return;
    try {
      const localParticipantName = room.sessionId || "defaultParticipantName";
      const combinedName = "v2WorldRoom";
      const token = await fetchLiveKitToken(combinedName, localParticipantName);
      const liveKitURL = 'wss://tworlds-wriqgndu.livekit.cloud';

      const roomInstance = new Room({
        videoCaptureDefaults: {
          resolution: VideoPresets.h720.resolution,
        },
      });
      await roomInstance.connect(liveKitURL, token);
      roomInstance
        .on(RoomEvent.ParticipantConnected, participant => console.log(`Participant connected: ${participant.identity}`))
        .on(RoomEvent.ParticipantDisconnected, participant => console.log(`Participant disconnected: ${participant.identity}`))
        .on(RoomEvent.TrackSubscribed, (track, publication, participant) => {
          if (track.kind === 'audio') {
            handleAudioTrack(track, participant);
          }
        })
        .on(RoomEvent.ActiveSpeakersChanged, (speakers) => {
          const speakerIds = new Set(speakers.map(s => s.identity));
          setActiveSpeakers(speakerIds);
        });

      const localAudioTrack = await createLocalAudioTrack();
      await roomInstance.localParticipant.publishTrack(localAudioTrack);
      setLkRoom(roomInstance);
      setIsJoined(true);
    } catch (error) {
      console.error('Error connecting to LiveKit:', error);
      setError(error);
    }
  };

  const toggleMute = () => {
    if (lkRoom) {
      const localParticipant = lkRoom.localParticipant;
      localParticipant.audioTracks.forEach(trackPublication => {
        if (isMuted) {
          trackPublication.track.unmute();
          console.log("Microphone unmuted");
        } else {
          trackPublication.track.mute();
          console.log("Microphone muted");
        }
      });
      setIsMuted(!isMuted);
    } else {
      console.log("Cannot toggle mute, LiveKit room is not connected.");
    }
  };

  useEffect(() => {
    if (!isLoggedIn && lkRoom) {
      lkRoom.disconnect();
      setLkRoom(null);
      setIsJoined(false);
    }
  }, [isLoggedIn, lkRoom]);

  const handleAudioTrack = (track, participant) => {
    const localSessionId = room.sessionId;
    if (participant.identity !== localSessionId) {
      const positionalAudio = createPositionalAudio(track, audioListener);
      setPositionalAudios((current) => ({
        ...current,
        [participant.identity]: positionalAudio,
      }));
    }
  };

  const createPositionalAudio = (audioTrack, listener) => {
    const audioEl = new Audio();
    audioEl.srcObject = audioTrack.mediaStream;
    const mediaStreamSource = listener.context.createMediaStreamSource(audioEl.srcObject);
    const positionalAudio = new PositionalAudio(listener);
    positionalAudio.setNodeSource(mediaStreamSource);
    return positionalAudio;
  };

  async function fetchLiveKitToken(roomName, localParticipantName) {
    const liveKitServerUrl = process.env.REACT_APP_LIVEKIT_SERVER_URL
    ? process.env.REACT_APP_LIVEKIT_SERVER_URL
    : 'http://localhost:3001';

  const response = await fetch(`${liveKitServerUrl}/livekit-token?roomName=${roomName}&participantName=${localParticipantName}`);

    if (!response.ok) {
      throw new Error(`Error fetching LiveKit token: ${response.statusText}`);
    }
    const data = await response.json();
    return data.token;
  }

  return (
    <LiveKitContext.Provider value={{ isJoined, setIsJoined, connectToLiveKit, lkRoom, setLkRoom, toggleMute, isMuted, error, audioListener, positionalAudios, activeSpeakers, setPositionalAudios }}>
      {children}
    </LiveKitContext.Provider>
  );
};
