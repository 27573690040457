import * as THREE from 'three';
import { useThree, extend } from '@react-three/fiber';
import { RenderPixelatedPass } from 'three-stdlib';
import { Stars, useGLTF, Sky, Environment } from '@react-three/drei';
import { Effects } from '@react-three/drei';
import { RigidBody } from '@react-three/rapier'
import React, { useRef, useState, useContext, useEffect } from 'react';
import { VideoStateContext } from '../tspace_components/contexts/VideoStateContext';
import { DoubleSide, MeshBasicMaterial, PlaneGeometry, Mesh, VideoTexture } from 'three';
import { useSocket } from '../tspace_components/contexts/SocketContext';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { EffectComposer, Bloom, N8AO } from '@react-three/postprocessing';
import InteractionPoint from '../tspace_components/components/InteractionPoint';

// Extend the RenderPixelatedPass
extend({ RenderPixelatedPass });

// Setup black bg
const Background = () => {
  const { gl } = useThree();

  useEffect(() => {
    gl.setClearColor('black');
  }, [gl]);

  return null;
};

// Navmesh component
const NavMesh = () => {
  const navMesh = useGLTF(`${process.env.PUBLIC_URL}/assets/scenes/GalleryNavMesh.glb`, true);

  // setting nevmesh to be invisible
  useEffect(() => {
    navMesh.scene.traverse(child => {
      if (child.isMesh) {
        child.material.transparent = true;
        child.material.opacity = 0;
        child.material.singleSided = true;
        child.visible = false;
      }
    });
  }, [navMesh.scene]);

  return (
    <>
      <RigidBody camCollidable colliders="trimesh" type="fixed">
        <primitive object={navMesh.scene} position={[0, 0, 0]} scale={[2, 2, 2]} />
      </RigidBody>
    </>
  );
};

const Hub = ({ characterRef }) => {
  const { size, camera, scene } = useThree();
  //const resolution = useMemo(() => new THREE.Vector2(size.width, size.height), [size]);

  const gltf = useGLTF(`${process.env.PUBLIC_URL}/assets/scenes/GalleryComingSoon.glb`, true);

  const { room } = useSocket();
  /* const { videoState, setVideoState, isLocallyPaused, setIsLocallyPaused, awaitingSync, setShouldSyncImmediately, shouldSyncImmediately, setAwaitingSync } = useContext(VideoStateContext);

  const videoRef = useRef(null);
  const [videoTexture, setVideoTexture] = useState(null);
  const [metadataLoaded, setMetadataLoaded] = useState(false);
  const [isConnected, setIsConnected] = useState(true);

  // 1. Setup videoRef and associated video events
  useEffect(() => {
    if (!videoRef.current) {
      videoRef.current = document.createElement('video');
      videoRef.current.src = process.env.PUBLIC_URL + '/assets/v2Video.mp4';
      videoRef.current.muted = true;
      videoRef.current.loop = true;
      videoRef.current.playsInline = true;
    }
  }, []);

  useEffect(() => {
    if (!videoRef.current) return;

    const video = videoRef.current;
    const handleMetadataLoaded = () => {
      setVideoState(prevState => ({ ...prevState, duration: video.duration }));
      setMetadataLoaded(true);

      if (room) {
        room.send('videoDuration', { duration: video.duration });
      }
    };
    video.addEventListener('loadedmetadata', handleMetadataLoaded);
    return () => {
      video.removeEventListener('loadedmetadata', handleMetadataLoaded);
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      setVideoTexture(new VideoTexture(videoRef.current));
    }
  }, [videoRef.current]);

  // 2. Set up the message handlers
  useEffect(() => {
    if (room) {
      room.onMessage('videoUpdate', (message) => {
        //console.log("Receiving videoUpdate from server", message);
        if (metadataLoaded) {
          setVideoState(prevState => ({ ...prevState, ...message, duration: prevState.duration }));
          if (awaitingSync) {
            videoRef.current.play();
            setAwaitingSync(false);
          }
        }
      });
    }
  }, [room, metadataLoaded]);

  // 3. Setup join/leave handlers
  useEffect(() => {
    if (room) {
      room.onLeave(() => {
        setIsConnected(false);
      });
      room.onJoin(() => {
        setIsConnected(true);
        setShouldSyncImmediately(true);
      });
    }
  }, [room]);

  useEffect(() => {
    if (!videoRef.current) return;
    const video = videoRef.current;
    const localTimeUpdateInterval = setInterval(() => {
      setVideoState(prevState => ({ ...prevState, currentTime: video.currentTime }));
    }, 1000);
    return () => {
      clearInterval(localTimeUpdateInterval);
    };
  }, []);

  // Server sync setup
  useEffect(() => {
    let syncInterval;
    if (isConnected && room) {
      syncInterval = setInterval(() => {
        const video = videoRef.current;
        //console.log("Sending videoUpdate to server", { currentTime: video.currentTime });
        room.send('videoUpdate', { videoState: { currentTime: video.currentTime } });
      }, 10000);
      if (shouldSyncImmediately) {
        //console.log("Sync Running...");
        const video = videoRef.current;
        //console.log("Immediate sync: Sending videoUpdate to server", { currentTime: video.currentTime });
        room.send('videoUpdate', { videoState: { currentTime: video.currentTime } });
        setShouldSyncImmediately(false);
      }
    }
    return () => {
      clearInterval(syncInterval);
    };
  }, [isConnected, room, shouldSyncImmediately]);

  useEffect(() => {
    if (!videoRef.current) return;
    const video = videoRef.current;
    const tolerance = 0.5;
    if (isLocallyPaused) {
      video.pause();
    } else {
      video.play();
      if (Math.abs(video.currentTime - videoState.currentTime) > tolerance) {
        video.currentTime = videoState.currentTime;
      }
    }
  }, [videoState, isLocallyPaused]);

  // Cleanup
  useEffect(() => {
    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    };
  }, []);

  const videoMaterial = videoTexture ? new MeshBasicMaterial({ map: videoTexture }) : null;
  const videoScreenGeometry = new PlaneGeometry(16, 9); */

  return (
    <>
      {/* <InteractionPoint
        id="interaction-1"
        position={[0, 1, 16.2]} // Adjust position as needed
        text="TRIBER STUDIOS! is an artech studio based in Brooklyn, NY."
        imageSrc={'/assets/TribeFactCircle.png'} // Replace with your image path
      /> */}
      {gltf.scene &&
        <primitive camCollidable object={gltf.scene} position={[0, -1, 0]} scale={[2, 2, 2]} rotation={[0, Math.PI, 0]}/>
      }
      <NavMesh />
      <ambientLight
        castShadow
        position={[10, 20, 20]}
        intensity={0.2}
      />
      <pointLight
        castShadow
        position={[10, 5, 0]}
        intensity={40}
      />
      <pointLight
        castShadow
        position={[-10, 5, 0]}
        intensity={40}
      />
      <pointLight
        castShadow
        position={[0, 5, -10]}
        intensity={40}
      />
      <pointLight
        castShadow
        position={[0, 5, 10]}
        intensity={40}
      />
      
      <Background />
      {/* <Sky/> */}
      {/* Add the EffectComposer and Pixelation effect (Commenting out for now) */}
      {/* <EffectComposer> */}
      {/* <CustomPixelationEffect pixelSize={4.0} /> */}
      {/* Other passes */}
      {/* </EffectComposer> */}
      {/* <Effects>
        <renderPixelatedPass args={[resolution, 1, scene, camera]} />
      </Effects> */}
      {/* {videoMaterial && (
        <mesh
          geometry={videoScreenGeometry}
          material={videoMaterial}
          position={[0, 5.7, 17.1]}
          rotation={[0, Math.PI, 0]}
          scale={[0.63, 0.63, 0.63]}
        />
      )} */}
      <Stars />
      <EffectComposer>
        <N8AO
          aoRadius={10}
          distanceFalloff={0.5}
          intensity={1.5}
          screenSpaceRadius
          halfRes
        />
        <Bloom
          intensity={1} // Adjust intensity as needed
          kernelSize={3} // Use LARGE for a more pronounced effect
          luminanceThreshold={0.85}
          luminanceSmoothing={0.05}
          mipmapBlur={true}
        />
      </EffectComposer>
      <Environment files={"/assets/scenes/satara_night.hdr"} background backgroundIntensity={0.2} backgroundBlurriness={0.7}/>
    </>
  );
};

export default Hub;

