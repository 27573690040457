import React, { useEffect, useState, useContext } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import './index.css';
import App from './App';
import "@aws-amplify/ui-react/styles.css";
import { ThemeProvider, createTheme } from "@aws-amplify/ui-react";
import reportWebVitals from './reportWebVitals';
import { GlobalProvider, GlobalContext } from './tspace_components/contexts/GlobalStore';
import { Amplify } from 'aws-amplify';
import { DataStore, AuthModeStrategyType } from 'aws-amplify/datastore';
import awsExports from './aws-exports';
import { CookieStorage, Hub } from "aws-amplify/utils";
import AllProviders from './tspace_components/contexts/AllProviders';
import AuthenticatorWrapper from './tspace_components/components/AuthenticatorWrapper';
import ProfileSetup from './tspace_components/components/ProfileSetup';
import { studioTheme } from './ui-components';
import { cognitoUserPoolsTokenProvider } from '@aws-amplify/auth/cognito';

const root = ReactDOM.createRoot(document.getElementById('root'));

const updatedTheme = createTheme({
  // Extend the theme to update the button color
  name: "my-theme-updates",
  tokens: {
    components: {
      button: {
        primary: {
          backgroundColor: {
            value: "#1f1f1f"
          },
        },
      },
    },
  },
}, studioTheme)


document.addEventListener(
  'touchmove',
  function (event) {
    if (!event.target.classList.contains('allow-scrolling')) {
      event.preventDefault();
    }
  },
  { passive: false }
);

Amplify.configure(awsExports);

// Set the auth mode strategy for DataStore
DataStore.configure({
  authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
});


cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({
  domain: '.triber.space', // Notice the dot at the beginning
  // This is important for localhost testing; cookies for localhost may not behave as expected
  secure: true // Use secure cookies in production
}));

function AuthListener({ setAuthLoading }) {
  const navigate = useNavigate();
  const { checkUserProfile } = useContext(GlobalContext);

  useEffect(() => {
    const listener = async (data) => {
      const { payload } = data;
      if (payload.event === 'signedIn') {
        setAuthLoading(true);
        const profileExists = await checkUserProfile();
        setAuthLoading(false);
        if (!profileExists) {
          navigate('/profilesetup');
        } else {
          navigate('/');
        }
      }
      if (payload.event === 'signedOut') {
        console.log("user signed out");
      }
    };

    const hubListenerCancel = Hub.listen('auth', listener);

    return () => hubListenerCancel();
  }, [navigate, checkUserProfile, setAuthLoading]);

  return null;
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalProvider>
        <AllProviders>
          <ThemeProvider theme={updatedTheme}>
            <AuthWrapper />
          </ThemeProvider>
        </AllProviders>
      </GlobalProvider>
    </BrowserRouter>
  </React.StrictMode>
);

function AuthWrapper() {
  const [authLoading, setAuthLoading] = useState(false);

  return (
    <>
      <AuthListener setAuthLoading={setAuthLoading} />
      <Routes>
        <Route path="/*" element={<App />} />
        <Route path="/authenticate" element={<AuthenticatorWrapper authLoading={authLoading} />} />
        <Route path="/profilesetup" element={<ProfileSetup />} />
      </Routes>
    </>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
