import React from 'react';
import { CSSTransition } from 'react-transition-group';
import './SideDrawer.css';
import { useNavigate } from 'react-router-dom';

const SideDrawer = ({ isOpen, onClose }) => {
  const navItems = ['Watch', 'Listen', 'Shop'];
  const navigate = useNavigate();

  return (
    <>
      {isOpen && <div className="drawerOverlay" onClick={onClose} />}
      <CSSTransition
        in={isOpen}
        timeout={200}
        classNames="drawer"
        unmountOnExit
      >
        <div className="sideDrawer">
          <div className="closeButtonContainer">
            <div className="closeButton" onClick={onClose}>
              &times;
            </div>
          </div>
          {navItems.map((item, index) => (
            <div
              key={index}
              className="navItem"
              onClick={() => { navigate(`/${item}`); onClose(); }}
            >
              {item}
            </div>
          ))}
        </div>
      </CSSTransition>
    </>
  );
};

export default SideDrawer;
