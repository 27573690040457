import React, { useRef, useState, useEffect, useContext } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { PerspectiveCamera } from '@react-three/drei';
import Character from './Character';
import { useCharacterControls } from './useCharacterControls';
import * as THREE from 'three';
import { useSocket } from '../contexts/SocketContext';
import { usePlayerPositions } from '../contexts/PlayerPositionsProvider';
import { LiveKitContext } from '../contexts/LiveKitContext';

const ThirdPersonCamera = ({ characterRef }) => {
  const { setDefaultCamera, scene } = useThree();
  const cameraRef = useRef();
  const rigidBodyRef = useRef();
  const [joystickData, setJoystickData] = useState(null);
  const { updateLocalPlayerPosition } = usePlayerPositions();
  const { audioListener } = useContext(LiveKitContext);
  const raycaster = useRef(new THREE.Raycaster());

  // Socket context for multiplayer communication
  const { room } = useSocket();

  // Handle character updates (e.g., position, animation) over the network
  const handleCharacterUpdate = (characterData) => {
    if (!room) return;
    room.send('playerUpdate', characterData);
  };

  // Poll joystick data from localStorage to allow for joystick control (if implemented)
  useEffect(() => {
    const intervalId = setInterval(() => {
      const joystickDataStorage = localStorage.getItem('joystickData');
      if (joystickDataStorage) {
        setJoystickData(JSON.parse(joystickDataStorage));
      }
    }, 100);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  // Use custom hook for character controls
  const { animation, cameraAngle, setCameraAngle } = useCharacterControls(
    characterRef,
    handleCharacterUpdate,
    joystickData,
    rigidBodyRef
  );

  // camera configuration
  const cameraDistance = 4;
  const cameraHeight = 3;
  const smoothness = 0.5;

  // dragging state for camera control
  const [isDragging, setIsDragging] = useState(false);
  const [lastClientX, setLastClientX] = useState(null);
  const [lastClientY, setLastClientY] = useState(null);
  const [cameraVerticalAngle, setCameraVerticalAngle] = useState(0); // Initialized to 0 for clarity

  // attach audio listener to camera
  useEffect(() => {
    if (cameraRef.current && audioListener) {
      const listenerOffsetObject = new THREE.Object3D();
      listenerOffsetObject.position.set(0, -0.5, -cameraDistance);
      listenerOffsetObject.add(audioListener);
      cameraRef.current.add(listenerOffsetObject);

      //return () => cameraRef.current.remove(listenerOffsetObject);
    }
  }, [cameraRef, audioListener, cameraDistance]);

  // handlers for mouse drag to control camera orientation
  useEffect(() => {
    const handlePointerDown = (event) => {
      setIsDragging(true);
      setLastClientX(event.clientX);
      setLastClientY(event.clientY);
    };

    const handlePointerMove = (event) => {
      if (!isDragging) return;

      const deltaX = event.clientX - lastClientX;
      const deltaY = event.clientY - lastClientY;
      const dragSpeedScaler = 0.003;
      setCameraAngle(prevAngle => prevAngle - deltaX * dragSpeedScaler);
      setCameraVerticalAngle(prevAngle => Math.max(Math.min(prevAngle + deltaY * dragSpeedScaler, Math.PI / 10), -Math.PI / 5));

      setLastClientX(event.clientX);
      setLastClientY(event.clientY);
    };

    // Event listeners for drag control
    const canvas = document.querySelector('canvas');
    canvas?.addEventListener('pointerdown', handlePointerDown);
    canvas?.addEventListener('pointerup', () => setIsDragging(false));
    canvas?.addEventListener('pointermove', handlePointerMove);

    return () => {
      canvas?.removeEventListener('pointerdown', handlePointerDown);
      canvas?.removeEventListener('pointerup', () => setIsDragging(false));
      canvas?.removeEventListener('pointermove', handlePointerMove);
    };
  }, [isDragging, lastClientX, lastClientY]);

  // frame update for camera following the character
  useFrame(() => {
    if (!characterRef.current || !cameraRef.current) return;

    const character = characterRef.current.parent;
    const cameraOrientationAngle = cameraAngle + Math.PI;

    const yOffset = 2.5;
    
    // calculate 3PCam position/angle
    const horizontalDistance = Math.cos(cameraVerticalAngle) * cameraDistance;
    const verticalDistance = Math.sin(cameraVerticalAngle) * cameraDistance;
    const desiredPosition = new THREE.Vector3(
      character.position.x + Math.sin(cameraOrientationAngle) * horizontalDistance,
      character.position.y + cameraHeight + verticalDistance,
      character.position.z + Math.cos(cameraOrientationAngle) * horizontalDistance
    );

    // collision detection logic - set up raycaster
    const rayCastOffset = new THREE.Vector3(character.position.x, character.position.y + 2, character.position.z)
    const castStartPos = rayCastOffset;
    const directionVec = new THREE.Vector3().subVectors(desiredPosition, castStartPos);
    const directionVecMag = directionVec.length();
    const directionVecNormalized = directionVec.normalize();
    raycaster.current.set(castStartPos, directionVecNormalized);
    raycaster.current.far = directionVecMag;

    // grab all items that can collide with cam
    const checklist = scene.children.filter(child => child.camCollidable);

    // use raycaster to see if theres an intersection with collidable items
    const newIntersects = raycaster.current.intersectObjects(checklist, true);

    // create a clone of desired position - in the case of intersection, move cam position to final position
    let finalPosition = desiredPosition.clone();

    if (newIntersects.length > 0) {
      const closestIntersect = newIntersects[0];
      if (closestIntersect.distance < directionVecMag) {
        finalPosition = castStartPos.add(directionVecNormalized.multiplyScalar(closestIntersect.distance - 0.1));
      }
    }

    //use final position as the cam destination
    cameraRef.current.position.lerp(finalPosition, smoothness);
    cameraRef.current.lookAt(character.position.x, character.position.y + yOffset, character.position.z);

    // Update local player position to be able to track distance from other players
    updateLocalPlayerPosition(character.position);
  });

  return (
    <>
      <Character ref={characterRef} animation={animation} rigidBodyRef={rigidBodyRef} />
      <PerspectiveCamera
        ref={cameraRef}
        makeDefault
        aspect={window.innerWidth / window.innerHeight}
        fov={75}
        near={0.05}
        far={1000}
        onUpdate={setDefaultCamera}
      />
    </>
  );
};

export default ThirdPersonCamera;
