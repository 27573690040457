import { useState, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { useKeyboardControls } from './useKeyboardControls';
import * as THREE from 'three';
import { useLocation } from 'react-router-dom';

// Define a custom hook for character controls
export const useCharacterControls = (characterRef, onUpdate, joystickData, rigidBodyRef) => {
  // Animation states
  const idle = 'idle';
  const walk = 'walk';

  // State hooks for managing character control properties
  const [cameraAngle, setCameraAngle] = useState(0); // Controls the camera's rotation angle around the character
  const [animation, setAnimation] = useState(idle); // Current animation state of the character
  const { up, down, left, right } = useKeyboardControls(); // Keyboard controls for character movement
  const [characterPosition, setCharacterPosition] = useState(new THREE.Vector3()); // Tracks the character's position in the scene
  const [lastDesiredRotationAngle, setLastDesiredRotationAngle] = useState(0); // Tracks the last desired rotation angle for smooth transitions
  const location = useLocation();
  
  // Initialize character setup
  useEffect(() => {
    // Ensure references are valid before proceeding
    if (!characterRef.current || !rigidBodyRef.current) {
      return;
    }

    // Idk why we need this exactly but we do - it makes animations work
    characterRef.current.body = new THREE.Vector3(0,0,0);
  }, [characterRef, rigidBodyRef]);

  useEffect(() => {
    const teleportationSettings = {
      '/Listen': { position: new THREE.Vector3(16, 0, 15), cameraAngle: Math.PI / 2 },
      '/Watch': { position: new THREE.Vector3(16, 0, -15), cameraAngle: Math.PI },
      '/Gallery': { position: new THREE.Vector3(-16, 0, 15), cameraAngle: 0 },
    };
  
    const settings = teleportationSettings[location.pathname];
    if (settings && rigidBodyRef.current && characterRef.current) {
      // Teleport the character
      rigidBodyRef.current.setTranslation(settings.position, true);
  
      // Adjust camera and character angle angle for the new orientation
      setCameraAngle(settings.cameraAngle);
      setLastDesiredRotationAngle(settings.cameraAngle);
    }
  }, [location, rigidBodyRef, characterRef]);

  // Frame update loop for handling real-time character control and animation updates
  useFrame(() => {
    // Early exit if references are not available
    if (!characterRef.current || !rigidBodyRef.current) return;
    
    let desiredRotationAngle = 0;
    const movementSpeed = 7; // Speed at which the character moves
    const rotationSpeed = 0.03; // Speed at which the character rotates
    const character = characterRef.current;
    const forward = new THREE.Vector3(0, 0, -1); // Vector representing the forward direction
    const characterQuaternion = new THREE.Quaternion().copy(character.quaternion);

    // Apply the character's current rotation to the forward vector
    forward.applyQuaternion(characterQuaternion);

    // Handle forward and backward movement
    if (up || down) {
      const directionMultiplier = up ? -1 : -1;
      rigidBodyRef.current.setLinvel({
        x: forward.x * movementSpeed * directionMultiplier,
        y: 0,
        z: forward.z * movementSpeed * directionMultiplier
      }, true);
    } else {
      // Stop movement if neither forward nor backward keys are pressed
      rigidBodyRef.current.setLinvel({x: 0, y: 0, z: 0}, true);
    }

    // Handle left and right rotation
    if (left || right) {
      const rotationDirection = left ? 1 : -1; // Positive for left; negative for right
      setCameraAngle(cameraAngle + rotationSpeed * rotationDirection);
    }

    const maxForce = 1;
    if (joystickData) {
      const joystickAngle = joystickData.angle - Math.PI / 2;
      const moveDirection = new THREE.Vector3(
        Math.sin(joystickAngle + cameraAngle),
        0,
        Math.cos(joystickAngle + cameraAngle)
      );

      // Clamp the force value to not exceed maxForce
      const clampedForce = Math.min(joystickData.force, maxForce);

      rigidBodyRef.current.setLinvel({
        x: moveDirection.x * movementSpeed * clampedForce,
        y: 0,
        z: moveDirection.z * movementSpeed * clampedForce
      }, true);

      // Adjust rotation based on joystick input
      desiredRotationAngle = joystickAngle + cameraAngle;
      setLastDesiredRotationAngle(desiredRotationAngle);
    }

    // Determine the desired rotation angle based on keyboard input
    desiredRotationAngle = up
        ? cameraAngle
        : down
          ? cameraAngle + Math.PI
          : lastDesiredRotationAngle;

    // Update the last desired rotation angle for smooth transitions
    if (up || down) {
      setLastDesiredRotationAngle(desiredRotationAngle);
    }

    // Apply the new rotation to the character
    const newQuaternion = new THREE.Quaternion();
    newQuaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), desiredRotationAngle);
    character.quaternion.copy(newQuaternion);

    // Update the animation state based on movement
    const animationState = up || down || joystickData ? walk : idle;
    if (animation !== animationState) {
      setAnimation(animationState);
    }

    // Notify parent component (or any subscriber) of the character update
    onUpdate({
      position: character.parent.position,
      rotation: desiredRotationAngle,
      animation: animationState,
    });
    // Update the tracked character position
    setCharacterPosition(character.parent.position);
  });

  // Expose control properties for external use
  return { cameraAngle, setCameraAngle, animation, characterPosition };
};
