import React, { useState, useContext, useEffect } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { GlobalContext } from '../../tspace_components/contexts/GlobalStore';
import { updateProfile } from '../../graphql/mutations';
import { generateClient } from 'aws-amplify/api';
import { EffectComposer, Bloom } from '@react-three/postprocessing';

const client = generateClient();

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '24px',
    backgroundColor: '#1f1f1f',
  },
  avatarContainer: {
    position: 'relative',
    width: '100%',
    height: '80vh',
    backgroundColor: '#1f1f1f',
    borderRadius: '24px',
    marginTop: '24px',
    overflow: 'hidden',
    maxWidth: '520px',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingText: {
    position: 'absolute',
    color: 'white',
    fontFamily: 'Work Sans',
    fontSize: '16px',
    zIndex: 10,
    backgroundColor: '#1f1f1f',
    padding: '10px 20px',
    borderRadius: '8px',
  },
  sliderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '24px',
  },
  label: {
    color: 'white',
    fontFamily: 'Work Sans',
    fontSize: '16px',
    marginRight: '16px',
  },
  slider: {
    width: '36px',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  saveButton: {
    backgroundColor: '#d9e4ec',
    color: 'black',
    fontFamily: 'Work Sans',
    fontSize: '20px',
    fontWeight: '500',
    padding: '8px 80px',
    border: 'none',
    borderRadius: '24px',
    marginTop: '24px',
    marginBottom: '24px',
    cursor: 'pointer',
    opacity: 0.5,
    pointerEvents: 'none',
  },
  activeSaveButton: {
    backgroundColor: '#d9e4ec',
    color: 'black',
    fontFamily: 'Work Sans',
    fontSize: '20px',
    fontWeight: '500',
    padding: '8px 80px',
    border: 'none',
    borderRadius: '24px',
    marginTop: '24px',
    marginBottom: '24px',
    cursor: 'pointer',
    opacity: 1,
    pointerEvents: 'auto',
  },
};

const Avatar = ({ color, onLoaded }) => {
  const { scene, animations } = useGLTF('/assets/TriberCharacterThinner2.glb');
  const [mixer] = useState(() => new THREE.AnimationMixer(scene));
  const { gl } = useThree();

  useEffect(() => {
    gl.setClearColor('#222222');
  }, [gl]);

  useEffect(() => {
    if (mixer && animations.length > 0) {
      const action = mixer.clipAction(animations[0]);
      action.play();
    }
  }, [animations, mixer]);

  useEffect(() => {
    if (scene) {
      scene.traverse((child) => {
        if (child.isMesh) {
          child.material.color.set(color);
          child.material.emissive.set(color);
          child.material.emissiveIntensity = 115;
        }
      });
      onLoaded(); // Signal that the scene has fully loaded
    }
  }, [color, scene, onLoaded]);

  useFrame((_, delta) => {
    mixer.update(delta);
  });

  return <primitive object={scene} />;
};

const AvatarEditor = () => {
  const { currentUserProfile, setCurrentUserProfile, avatarColor, setAvatarColor } = useContext(GlobalContext);
  const [localColor, setLocalColor] = useState(avatarColor);
  const [isColorChanged, setIsColorChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentUserProfile) {
      const userColor = currentUserProfile.baseAvatarColor || avatarColor;
      setLocalColor(userColor);
      setAvatarColor(userColor);
    }
  }, [currentUserProfile]);

  const handleChangeColor = (event) => {
    const newColor = event.target.value;
    setLocalColor(newColor);
    setIsColorChanged(newColor !== avatarColor);
  };

  useEffect(() => {
    setAvatarColor(localColor);
  }, [localColor, setAvatarColor]);

  const handleSave = async () => {
    if (isColorChanged) {
      try {
        const result = await client.graphql({
          query: updateProfile,
          variables: {
            input: {
              id: currentUserProfile.id,
              baseAvatarColor: localColor,
            },
          },
        });

        setCurrentUserProfile(result.data.updateProfile);
        setIsColorChanged(false);
        console.log('Color saved:', localColor);
      } catch (error) {
        console.error('Error saving color:', error);
      }
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.avatarContainer}>
        {isLoading && <div style={styles.loadingText}></div>}
        <Canvas camera={{ position: [0, 3.5, 7] }}>
          <OrbitControls enablePan={false} enableZoom={false} target={[0, 3.5, 0]} />
          <ambientLight intensity={3} />
          <Avatar color={localColor} onLoaded={() => setIsLoading(false)} /> {/* Pass onLoaded callback */}
          <EffectComposer>
            <Bloom
              intensity={1}
              kernelSize={3}
              luminanceThreshold={0.85}
              luminanceSmoothing={0.05}
              mipmapBlur={true}
            />
          </EffectComposer>
        </Canvas>
      </div>
      <div style={styles.sliderWrapper}>
        <label style={styles.label}>Accent Color:</label>
        <input
          style={styles.slider}
          type="color"
          value={localColor}
          onChange={handleChangeColor}
        />
      </div>
      <button 
        style={isColorChanged ? styles.activeSaveButton : styles.saveButton} 
        onClick={handleSave}
      >
        Save
      </button>
    </div>
  );
};

export default AvatarEditor;
