/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  Text,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import {
  getInventoryItem,
  listProducts,
  listProfiles,
} from "../graphql/queries";
import { updateInventoryItem } from "../graphql/mutations";
const client = generateClient();
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function InventoryItemUpdateForm(props) {
  const {
    id: idProp,
    inventoryItem: inventoryItemModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    owner: undefined,
    product: undefined,
    acquiredDate: "",
    status: "",
  };
  const [owner, setOwner] = React.useState(initialValues.owner);
  const [ownerLoading, setOwnerLoading] = React.useState(false);
  const [ownerRecords, setOwnerRecords] = React.useState([]);
  const [product, setProduct] = React.useState(initialValues.product);
  const [productLoading, setProductLoading] = React.useState(false);
  const [productRecords, setProductRecords] = React.useState([]);
  const [acquiredDate, setAcquiredDate] = React.useState(
    initialValues.acquiredDate
  );
  const [status, setStatus] = React.useState(initialValues.status);
  const autocompleteLength = 10;
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = inventoryItemRecord
      ? { ...initialValues, ...inventoryItemRecord, owner, product }
      : initialValues;
    setOwner(cleanValues.owner);
    setCurrentOwnerValue(undefined);
    setCurrentOwnerDisplayValue("");
    setProduct(cleanValues.product);
    setCurrentProductValue(undefined);
    setCurrentProductDisplayValue("");
    setAcquiredDate(cleanValues.acquiredDate);
    setStatus(cleanValues.status);
    setErrors({});
  };
  const [inventoryItemRecord, setInventoryItemRecord] = React.useState(
    inventoryItemModelProp
  );
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getInventoryItem.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getInventoryItem
        : inventoryItemModelProp;
      const ownerRecord = record ? await record.owner : undefined;
      setOwner(ownerRecord);
      const productRecord = record ? await record.product : undefined;
      setProduct(productRecord);
      setInventoryItemRecord(record);
    };
    queryData();
  }, [idProp, inventoryItemModelProp]);
  React.useEffect(resetStateValues, [inventoryItemRecord, owner, product]);
  const [currentOwnerDisplayValue, setCurrentOwnerDisplayValue] =
    React.useState("");
  const [currentOwnerValue, setCurrentOwnerValue] = React.useState(undefined);
  const ownerRef = React.createRef();
  const [currentProductDisplayValue, setCurrentProductDisplayValue] =
    React.useState("");
  const [currentProductValue, setCurrentProductValue] =
    React.useState(undefined);
  const productRef = React.createRef();
  const getIDValue = {
    owner: (r) => JSON.stringify({ id: r?.id }),
    product: (r) => JSON.stringify({ id: r?.id }),
  };
  const ownerIdSet = new Set(
    Array.isArray(owner)
      ? owner.map((r) => getIDValue.owner?.(r))
      : getIDValue.owner?.(owner)
  );
  const productIdSet = new Set(
    Array.isArray(product)
      ? product.map((r) => getIDValue.product?.(r))
      : getIDValue.product?.(product)
  );
  const getDisplayValue = {
    owner: (r) => `${r?.username ? r?.username + " - " : ""}${r?.id}`,
    product: (r) => `${r?.itemName ? r?.itemName + " - " : ""}${r?.id}`,
  };
  const validations = {
    owner: [],
    product: [],
    acquiredDate: [],
    status: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const convertToLocal = (date) => {
    const df = new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      calendar: "iso8601",
      numberingSystem: "latn",
      hourCycle: "h23",
    });
    const parts = df.formatToParts(date).reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
    return `${parts.year}-${parts.month}-${parts.day}T${parts.hour}:${parts.minute}`;
  };
  const fetchOwnerRecords = async (value) => {
    setOwnerLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ username: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listProfiles.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listProfiles?.items;
      var loaded = result.filter(
        (item) => !ownerIdSet.has(getIDValue.owner?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setOwnerRecords(newOptions.slice(0, autocompleteLength));
    setOwnerLoading(false);
  };
  const fetchProductRecords = async (value) => {
    setProductLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ itemName: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listProducts.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listProducts?.items;
      var loaded = result.filter(
        (item) => !productIdSet.has(getIDValue.product?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setProductRecords(newOptions.slice(0, autocompleteLength));
    setProductLoading(false);
  };
  React.useEffect(() => {
    fetchOwnerRecords("");
    fetchProductRecords("");
  }, []);
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          owner: owner ?? null,
          product: product ?? null,
          acquiredDate: acquiredDate ?? null,
          status: status ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(
                    fieldName,
                    item,
                    getDisplayValue[fieldName]
                  )
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(
                fieldName,
                modelFields[fieldName],
                getDisplayValue[fieldName]
              )
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const modelFieldsToSave = {
            ownerId: modelFields?.owner?.id ?? null,
            productId: modelFields?.product?.id ?? null,
            acquiredDate: modelFields.acquiredDate ?? null,
            status: modelFields.status ?? null,
          };
          await client.graphql({
            query: updateInventoryItem.replaceAll("__typename", ""),
            variables: {
              input: {
                id: inventoryItemRecord.id,
                ...modelFieldsToSave,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "InventoryItemUpdateForm")}
      {...rest}
    >
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              owner: value,
              product,
              acquiredDate,
              status,
            };
            const result = onChange(modelFields);
            value = result?.owner ?? value;
          }
          setOwner(value);
          setCurrentOwnerValue(undefined);
          setCurrentOwnerDisplayValue("");
        }}
        currentFieldValue={currentOwnerValue}
        label={"Owner"}
        items={owner ? [owner] : []}
        hasError={errors?.owner?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("owner", currentOwnerValue)
        }
        errorMessage={errors?.owner?.errorMessage}
        getBadgeText={getDisplayValue.owner}
        setFieldValue={(model) => {
          setCurrentOwnerDisplayValue(
            model ? getDisplayValue.owner(model) : ""
          );
          setCurrentOwnerValue(model);
        }}
        inputFieldRef={ownerRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Owner"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Profile"
          value={currentOwnerDisplayValue}
          options={ownerRecords
            .filter((r) => !ownerIdSet.has(getIDValue.owner?.(r)))
            .map((r) => ({
              id: getIDValue.owner?.(r),
              label: getDisplayValue.owner?.(r),
            }))}
          isLoading={ownerLoading}
          onSelect={({ id, label }) => {
            setCurrentOwnerValue(
              ownerRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentOwnerDisplayValue(label);
            runValidationTasks("owner", label);
          }}
          onClear={() => {
            setCurrentOwnerDisplayValue("");
          }}
          defaultValue={owner}
          onChange={(e) => {
            let { value } = e.target;
            fetchOwnerRecords(value);
            if (errors.owner?.hasError) {
              runValidationTasks("owner", value);
            }
            setCurrentOwnerDisplayValue(value);
            setCurrentOwnerValue(undefined);
          }}
          onBlur={() => runValidationTasks("owner", currentOwnerDisplayValue)}
          errorMessage={errors.owner?.errorMessage}
          hasError={errors.owner?.hasError}
          ref={ownerRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "owner")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              owner,
              product: value,
              acquiredDate,
              status,
            };
            const result = onChange(modelFields);
            value = result?.product ?? value;
          }
          setProduct(value);
          setCurrentProductValue(undefined);
          setCurrentProductDisplayValue("");
        }}
        currentFieldValue={currentProductValue}
        label={"Product"}
        items={product ? [product] : []}
        hasError={errors?.product?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("product", currentProductValue)
        }
        errorMessage={errors?.product?.errorMessage}
        getBadgeText={getDisplayValue.product}
        setFieldValue={(model) => {
          setCurrentProductDisplayValue(
            model ? getDisplayValue.product(model) : ""
          );
          setCurrentProductValue(model);
        }}
        inputFieldRef={productRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Product"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Product"
          value={currentProductDisplayValue}
          options={productRecords
            .filter((r) => !productIdSet.has(getIDValue.product?.(r)))
            .map((r) => ({
              id: getIDValue.product?.(r),
              label: getDisplayValue.product?.(r),
            }))}
          isLoading={productLoading}
          onSelect={({ id, label }) => {
            setCurrentProductValue(
              productRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentProductDisplayValue(label);
            runValidationTasks("product", label);
          }}
          onClear={() => {
            setCurrentProductDisplayValue("");
          }}
          defaultValue={product}
          onChange={(e) => {
            let { value } = e.target;
            fetchProductRecords(value);
            if (errors.product?.hasError) {
              runValidationTasks("product", value);
            }
            setCurrentProductDisplayValue(value);
            setCurrentProductValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("product", currentProductDisplayValue)
          }
          errorMessage={errors.product?.errorMessage}
          hasError={errors.product?.hasError}
          ref={productRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "product")}
        ></Autocomplete>
      </ArrayField>
      <TextField
        label="Acquired date"
        isRequired={false}
        isReadOnly={false}
        type="datetime-local"
        value={acquiredDate && convertToLocal(new Date(acquiredDate))}
        onChange={(e) => {
          let value =
            e.target.value === "" ? "" : new Date(e.target.value).toISOString();
          if (onChange) {
            const modelFields = {
              owner,
              product,
              acquiredDate: value,
              status,
            };
            const result = onChange(modelFields);
            value = result?.acquiredDate ?? value;
          }
          if (errors.acquiredDate?.hasError) {
            runValidationTasks("acquiredDate", value);
          }
          setAcquiredDate(value);
        }}
        onBlur={() => runValidationTasks("acquiredDate", acquiredDate)}
        errorMessage={errors.acquiredDate?.errorMessage}
        hasError={errors.acquiredDate?.hasError}
        {...getOverrideProps(overrides, "acquiredDate")}
      ></TextField>
      <TextField
        label="Status"
        isRequired={false}
        isReadOnly={false}
        value={status}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              owner,
              product,
              acquiredDate,
              status: value,
            };
            const result = onChange(modelFields);
            value = result?.status ?? value;
          }
          if (errors.status?.hasError) {
            runValidationTasks("status", value);
          }
          setStatus(value);
        }}
        onBlur={() => runValidationTasks("status", status)}
        errorMessage={errors.status?.errorMessage}
        hasError={errors.status?.hasError}
        {...getOverrideProps(overrides, "status")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || inventoryItemModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || inventoryItemModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
