import React, { useEffect } from "react";
import { Route, Routes} from "react-router-dom";
import Hub from "./Hub";
import Watch from "./Watch";
import Listen from "./Listen";
import Gallery from "./Gallery";

const ScenesHandler = ({ characterRef }) => {

  return (
    <>
      {}
        <Routes>
          <Route
            key="Hub"
            path="/"
            element={<Hub characterRef={characterRef}/>}
          />
          <Route
            key="Watch"
            path="/Watch"
            element={<Hub characterRef={characterRef}/>}
          />
          <Route
            key="Listen"
            path="/Listen"
            element={<Hub characterRef={characterRef}/>}
          />
          <Route
            key="Gallery"
            path="/Gallery"
            element={<Hub characterRef={characterRef}/>}
          />
        </Routes>
      {}
    </>
  );
};

export default ScenesHandler;



