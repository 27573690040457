import React, { useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import './MicrophoneMenu.css';
import { faSignInAlt, faSignOutAlt, faMicrophoneSlash, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDialogue } from '../tspace_components/contexts/DialogueContext';
import { useLiveKit } from '../tspace_components/contexts/LiveKitContext';

const MicrophoneMenu = ({ isVisible }) => {
  const { isJoined, connectToLiveKit, lkRoom, setLkRoom, toggleMute, isMuted } = useLiveKit();

  const handleJoinLeaveClick = () => {
    if (!isJoined) {
      connectToLiveKit();
    } else if (lkRoom) {
      lkRoom.disconnect();
      setLkRoom(null);
    }
  };

  const handleMuteToggle = () => {
    toggleMute();
  };

  return (
    <CSSTransition
      in={isVisible}
      timeout={300}
      classNames="dropdown"
      unmountOnExit
    >
      <div className="microphone-menu">
        <div className="menu-header">
          <div className="menu-title">Voice Chat</div>
        </div>
        <div className="menu-divider"></div>

        {isJoined ? (
          <>
            <div className="menu-item-mic">
              <span className="menu-item-text">Microphone</span>
              <div className="mic-icon-container" onClick={handleMuteToggle}>
                <FontAwesomeIcon icon={isMuted ? faMicrophoneSlash : faMicrophone} size="lg" />
              </div>
            </div>
            <div className="menu-divider"></div>
            <div className="menu-item-join-leave" onClick={handleJoinLeaveClick}>
              <FontAwesomeIcon icon={faSignOutAlt} fixedWidth size="lg" />
              <span className="menu-item-text">Leave Voice Chat</span>
            </div>
          </>
        ) : (
          <div className="menu-item-join-leave" onClick={handleJoinLeaveClick}>
            <FontAwesomeIcon icon={faSignInAlt} fixedWidth size="lg" />
            <span className="menu-item-text">Join Voice Chat</span>
          </div>
        )}
      </div>
    </CSSTransition>
  );
};

export default MicrophoneMenu;
