import React, { useState, useEffect, useContext } from 'react';
import './ProfileView.css';
import { GlobalContext } from '../contexts/GlobalStore';

const ProfileView = ({ user, onClose }) => {
  const {
    currentUserProfile,
    handleFollowUser,
    handleUnfollowUser,
    checkIfFollowing,
    getFollowRecordId,
    triggerFollowAction,
    manageConnectionOnFollow,
    manageConnectionOnUnfollow,
    findConnectionId,
    hasSpokenNear,
  } = useContext(GlobalContext);

  const [isFollowing, setIsFollowing] = useState(false);
  const [connectionId, setConnectionId] = useState(null);
  const [canConnect, setCanConnect] = useState(false);

  useEffect(() => {
    const init = async () => {
      if (user.profile.id !== currentUserProfile.id) {
        const followingStatus = await checkIfFollowing(currentUserProfile.id, user.profile.id);
        setIsFollowing(followingStatus);

        const connId = await findConnectionId(currentUserProfile.id, user.profile.id);
        setConnectionId(connId);

        const hasSpoken = hasSpokenNear[user.profile.id];
        setCanConnect(hasSpoken);
      }
    };
    init();
  }, [user, currentUserProfile, hasSpokenNear]);

  useEffect(() => {
    const updateFollowStatus = async () => {
      if (user.profile.id !== currentUserProfile.id) {
        const followingStatus = await checkIfFollowing(currentUserProfile.id, user.profile.id);
        setIsFollowing(followingStatus);
        triggerFollowAction();
      }
    };

    updateFollowStatus();
  }, [user, currentUserProfile, checkIfFollowing]);

  const cleanUsername = (username) => {
    return username?.replace(/^@/, '');
  };

  const renderConnectionStatus = () => {
    if (!isFollowing) {
      return null;
    }

    if (connectionId) {
      return <p className="statusText">Connection Successful ✅</p>;
    }

    return <p className="statusText">Connection Pending...</p>;
  };

  const renderFollowButton = () => {
    return (
      <>
        {renderConnectionStatus()}
        <div className="connect-button-container" style={{ width: '100%' }}>
          <button
            className="follow-buttonProfile"
            onClick={isFollowing ? handleUnfollowButtonClick : handleFollowButtonClick}
            disabled={!canConnect && !isFollowing}
          >
            {isFollowing ? 'Remove Connection' : 'Connect'}
          </button>
          {!canConnect && !isFollowing && (
            <div className="cannot-connect-message">
              You must use text or voice chat near this person to connect.
            </div>
          )}
        </div>
      </>
    );
  };

  const handleFollowButtonClick = async () => {
    if (user) {
      await handleFollowUser(user.profile.id);
      setIsFollowing(true);
      await manageConnectionOnFollow(currentUserProfile.id, user.profile.id);
    }
  };

  const handleUnfollowButtonClick = async () => {
    if (user) {
      const followRecordId = await getFollowRecordId(currentUserProfile.id, user.profile.id);
      if (followRecordId) {
        await handleUnfollowUser(followRecordId);
        setIsFollowing(false);
        await manageConnectionOnUnfollow(currentUserProfile.id, user.profile.id);
        triggerFollowAction();
      }
    }
  };

  if (!user) return null;

  return (
    <div className="profile-view-overlay" onClick={onClose}>
      <div className="profile-view-modal" onClick={(e) => e.stopPropagation()}>
        <div className="profile-view-header">
          <div className="profile-avatar" />
          <h2 className="profile-username">@{cleanUsername(user.userName)}</h2>
        </div>
        {user.profile.bio && <p className="profile-bio">{user.profile.bio}</p>}
        {renderFollowButton()}
        <div className="profile-socials">
          <div className="social-item">
            <label>Instagram</label>
            <a
              href={`https://instagram.com/${cleanUsername(user.profile?.instagram)}`}
              target="_blank"
              rel="noopener noreferrer"
              className="social-username"
            >
              @{cleanUsername(user.profile?.instagram) || 'N/A'}
            </a>
          </div>
          <div className="social-item">
            <label>Twitter</label>
            <a
              href={`https://twitter.com/${cleanUsername(user.profile?.twitter)}`}
              target="_blank"
              rel="noopener noreferrer"
              className="social-username"
            >
              @{cleanUsername(user.profile?.twitter) || 'N/A'}
            </a>
          </div>
        </div>
        <button className="profile-close-button" onClick={onClose}>
          ×
        </button>
      </div>
    </div>
  );
};

export default ProfileView;
