import React, { useState, useEffect } from 'react';
import './PointsNotification.css'; // Assuming you're using a separate CSS file for styles
import chromeTribeTarget from '../assets/ChromeTribeTarget.svg';

const PointsNotification = ({ reason, points }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [fadeClass, setFadeClass] = useState('');

    useEffect(() => {
        if (points > 0 || reason.toLowerCase().includes('pending')) {
            setIsVisible(true);
            
            // Delay the application of fade-in to ensure smooth transition
            const fadeInTimer = setTimeout(() => {
                setFadeClass('fade-in');
            }, 10); // Small delay to ensure component has mounted

            const fadeOutTimer = setTimeout(() => {
                setFadeClass('fade-out');
            }, 4500); // Start fade-out just before removing the element

            const hideTimer = setTimeout(() => {
                setIsVisible(false);
            }, 5000); // Hide the notification after the fade-out is complete

            return () => {
                clearTimeout(fadeInTimer);
                clearTimeout(fadeOutTimer);
                clearTimeout(hideTimer);
            };
        }
    }, [points, reason]);

    if (!isVisible) return null; // Don't render if not visible

    return (
        <div className={`points-notification ${fadeClass}`}>
            <div className="notification-icon">
                <img src={chromeTribeTarget} alt="Icon" />
            </div>
            <div className="notification-content">
                <div className="reason">{reason}</div>
                <div className="points-earned">
                    {reason.toLowerCase().includes('pending')
                        ? '10 pending Tribe Points.'
                        : `You’ve earned ${points} Tribe Points.`}
                </div>
            </div>
        </div>
    );
};

export default PointsNotification;
