import React, { useRef, useState, useEffect, useContext } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { GlobalContext } from '../contexts/GlobalStore';
import { CapsuleCollider, RigidBody } from "@react-three/rapier";
import { Html } from '@react-three/drei';
import { useDialogue } from '../contexts/DialogueContext';
import { LiveKitContext } from '../contexts/LiveKitContext';
import { useSocket } from '../contexts/SocketContext';

const Character = React.forwardRef(({ animation, rigidBodyRef, ...props }, characterRef) => {
  const { scene, animations } = useGLTF(`${process.env.PUBLIC_URL}/assets/TriberCharacterThinner.glb`);
  const [mixer, setMixer] = useState(null);
  const { avatarColor, currentUserProfile } = useContext(GlobalContext); // Use avatarColor from context
  const [physicsReady, setPhysicsReady] = useState(null);

  const { lastMessage } = useDialogue();
  const { activeSpeakers } = useContext(LiveKitContext);
  const { room } = useSocket();

  useEffect(() => {
    const mixerInstance = new THREE.AnimationMixer(scene);
    setMixer(mixerInstance);
  }, [scene]);

  useEffect(() => {
    if (mixer && animations.length > 0) {
      mixer.stopAllAction();
      const clip = animations.find((clip) => clip.name === animation);
      if (clip) {
        const action = mixer.clipAction(clip);
        action.play();
      }
    }
  }, [mixer, animation, animations]);

  useFrame((_, deltaTime) => {
    if (mixer) {
      mixer.update(deltaTime);
    }
  });

  useEffect(() => {
    if (scene) {
      scene.traverse((child) => {
        if (child.isMesh && child.material) {
          child.material.color.set(avatarColor); // Use avatarColor from context
          if (child.material.emissive) {
            child.material.emissive.set(avatarColor);
            child.material.emissiveIntensity = 200;
          }
        }
      });
    }
  }, [scene, avatarColor]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPhysicsReady(true);
    }, 100);
  
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {physicsReady && (
        <RigidBody
          gravityScale={15}
          ref={rigidBodyRef}
          colliders={false}
          type="dynamic"
          lockRotations={true}
          dominanceGroup={1000}
          position={[8, 2, 0]}
          friction={1}
          linearDamping={0.5}  // Adjust this value to control damping
          angularDamping={0.5}
        >
          <CapsuleCollider position={[0, 1.2, 0]} args={[0.5, 0.75]} />
          <group
            ref={characterRef}
            {...props}
            scale={[.35, .35, .35]}
            rotation={[0, Math.PI, 0]}
            position={[0, 0, 0]}
            dispose={null}
            renderOrder={1002}
          >
            <primitive object={scene} dispose={null} />
          </group>

          {/* Name, Voice Icon, and Message Box */}
          <Html position={[0, 2.65, 0]} center style={{ pointerEvents: 'none' }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: 'rgba(31, 31, 31, 0.8)',
              color: 'white',
              padding: '4px 8px',
              borderRadius: '8px',
              fontFamily: 'Work Sans',
              fontSize: '14px',
              textAlign: 'center',
            }}>
              <div>{currentUserProfile?.username || 'Me'}</div>
              {activeSpeakers.has(room?.sessionId) && (
                <img
                  src="/assets/speaker.png"
                  alt="Audio Icon"
                  style={{ marginTop: '4px', width: '16px', height: '16px' }}
                />
              )}
            </div>
          </Html>

          {lastMessage && (
            <Html position={[0, 3, 0]} center style={{ pointerEvents: 'none' }}>
              <div style={{
                position: 'relative',
                backgroundColor: '#333',
                padding: '6px 10px',
                borderRadius: '8px',
                fontFamily: 'Work Sans',
                fontSize: '16px',
                color: 'white',
                textAlign: 'center',
                maxWidth: '500px',
                wordWrap: 'break-word',
              }}>
                {lastMessage}
                <div style={{
                    position: 'absolute',
                    bottom: '-8px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '0',
                    height: '0',
                    borderLeft: '8px solid transparent',
                    borderRight: '8px solid transparent',
                    borderTop: '8px solid #333',
                  }} />
              </div>
            </Html>
          )}
        </RigidBody>
      )}
    </>
  );
});

export default Character;
