import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

const PlayerPositionsContext = createContext();

export const PlayerPositionsProvider = ({ children }) => {
  const [localPlayerPosition, setLocalPlayerPosition] = useState({ x: 0, y: 0, z: 0 });
  const [otherPlayersPositions, setOtherPlayersPositions] = useState({});
  const [nearbyPlayers, setNearbyPlayers] = useState([]); // Use state instead of ref

  const updateLocalPlayerPosition = (position) => {
    setLocalPlayerPosition(position);
  };

  const updateOtherPlayerPosition = (playerId, newPosition) => {
    setOtherPlayersPositions(prevPositions => ({
      ...prevPositions,
      [playerId]: newPosition,
    }));
  };

  useEffect(() => {
    const checkProximity = () => {
      const newNearbyPlayers = Object.entries(otherPlayersPositions).reduce((acc, [playerId, position]) => {
        const distance = Math.sqrt(
          Math.pow(position.x - localPlayerPosition.x, 2) +
          Math.pow(position.y - localPlayerPosition.y, 2) +
          Math.pow(position.z - localPlayerPosition.z, 2)
        );

        if (distance < 5) {
          acc.push(playerId);
        }
        return acc;
      }, []);

      setNearbyPlayers(newNearbyPlayers);
    };

    checkProximity();
  }, [localPlayerPosition, otherPlayersPositions]);

  return (
    <PlayerPositionsContext.Provider value={{
      localPlayerPosition,
      otherPlayersPositions,
      updateLocalPlayerPosition,
      updateOtherPlayerPosition,
      nearbyPlayers, // Now provided as state
    }}>
      {children}
    </PlayerPositionsContext.Provider>
  );
};

export const usePlayerPositions = () => useContext(PlayerPositionsContext);
