import React, { useRef, useState, useEffect, useContext } from 'react';
import { useFrame } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { CapsuleCollider, RigidBody, vec3 } from "@react-three/rapier";
import * as THREE from 'three';
import { GlobalContext } from '../contexts/GlobalStore';
import { usePlayerPositions } from '../contexts/PlayerPositionsProvider';
import { Html } from '@react-three/drei';

const OtherPlayer = ({ id, animation, position, quaternion, color, positionalAudio, isActiveSpeaker, userName, profile, lastMessage }) => {
  const groupRef = useRef();
  const rigidBodyRef = useRef();
  const { proximityChatUsers, openUserProfile } = useContext(GlobalContext);
  const { nearbyPlayers } = usePlayerPositions();
  
  const [mixer, setMixer] = useState(null);
  const [model, setModel] = useState(null);
  const [animations, setAnimations] = useState([]);

  useEffect(() => {
    const loader = new GLTFLoader();
    loader.load(
      `${process.env.PUBLIC_URL}/assets/TriberCharacterNoCompress.glb`,
      (gltf) => {
        const scene = gltf.scene;
        const animations = gltf.animations;

        // Apply color and emissive settings after materials are fully initialized
        scene.traverse((child) => {
          if (child.isMesh) {
            const material = child.material.clone(); // Clone the material to ensure uniqueness
            material.color.set(color);
            material.emissive.set(color);
            material.emissiveIntensity = 200;
            child.material = material;
          }
        });

        setModel(scene);
        setAnimations(animations);

        const mixerInstance = new THREE.AnimationMixer(scene);
        setMixer(mixerInstance);
      },
      undefined,
      (error) => {
        console.error('An error happened while loading the GLTF model:', error);
      }
    );
  }, [color]);

  useEffect(() => {
    if (mixer && animations.length > 0 && model) {
      mixer.stopAllAction();
      const newAnimationClip = animations.find((clip) => clip.name === animation);
      if (newAnimationClip) {
        const newAnimationAction = mixer.clipAction(newAnimationClip);
        newAnimationAction.play();
      }
    }
  }, [mixer, animation, animations, model]);

  useEffect(() => {
    if (positionalAudio?.context?.state === "suspended") {
      positionalAudio.context.resume().catch((error) => {
        console.error("Failed to resume audio context:", error);
      });
    }
  }, [positionalAudio]);

  useEffect(() => {
    if (positionalAudio && model) {
      model.add(positionalAudio);
      positionalAudio.setDistanceModel('linear');
      positionalAudio.setRolloffFactor(1.5);
      positionalAudio.setRefDistance(1);
      positionalAudio.setMaxDistance(15);
    }
  }, [model, positionalAudio]);

  useFrame((state, delta) => {
    if (!rigidBodyRef.current || !groupRef.current) {
      return;
    }
    if (mixer) {
      mixer.update(delta);
    }

    if (groupRef.current) {
      groupRef.current.quaternion.copy(quaternion);
      groupRef.current.position.set(0, 0, 0);
    }

    const currentPos = rigidBodyRef.current.translation();
    const currentPosition = new THREE.Vector3(currentPos.x, currentPos.y, currentPos.z);
    const targetPosition = new THREE.Vector3(position.x, position.y, position.z);

    const direction = targetPosition.clone().sub(currentPosition).normalize();
    const distance = currentPosition.distanceTo(targetPosition);

    const speed = 7;
    const desiredVelocity = direction.multiplyScalar(Math.min(distance, speed * delta));
    rigidBodyRef.current.setLinvel(vec3(desiredVelocity.x, desiredVelocity.y, desiredVelocity.z), true);
  });

  const handleNameTagClick = (event) => {
    event.stopPropagation();
    console.log(`Player ${id} name tag clicked`);

    const user = proximityChatUsers.find(u => u.id === id);

    if (user) {
      openUserProfile(user);
    } else {
      console.log(`User with id ${id} not found in proximityChatUsers list.`);
    }
  };

  const handlePointerOver = () => {
    document.body.style.cursor = 'pointer';
  };

  const handlePointerOut = () => {
    document.body.style.cursor = 'default';
  };

  return (
    <RigidBody
      gravityScale={15}
      ref={rigidBodyRef}
      colliders={false}
      position={position}
      type="fixed"
      lockRotations={true}
    >
      <CapsuleCollider
        position={[0, 1.2, 0]}
        args={[0.5, 0.75]}
      />

      {nearbyPlayers.includes(id) && (
        <>
          <Html position={[0, 2.77, 0]} center style={{ pointerEvents: 'auto' }}>
            <button 
              onClick={handleNameTagClick} 
              style={{
                all: 'unset', // Remove all default button styles
                cursor: 'pointer', 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: 'rgba(31, 31, 31, 0.8)',
                color: 'white',
                padding: '4px 8px',
                borderRadius: '8px',
                fontFamily: 'Work Sans',
                fontSize: '14px',
                textAlign: 'center',
              }}>
              <div>{userName}</div>
              {isActiveSpeaker && (
                <img
                  src="/assets/speaker.png"
                  alt="Audio Icon"
                  style={{ marginTop: '4px', width: '16px', height: '16px' }}
                />
              )}
            </button>
          </Html>

          {lastMessage && (
            <Html position={[0, 3.2, 0]} center style={{ pointerEvents: 'none' }}>
              <div style={{
                position: 'relative',
                backgroundColor: '#222',
                padding: '6px 10px',
                borderRadius: '8px',
                fontFamily: 'Work Sans',
                fontSize: '14px',
                color: 'white',
                textAlign: 'center',
                maxWidth: '200px',
                wordWrap: 'break-word',
              }}>
                {lastMessage}
                <div style={{
                  position: 'absolute',
                  bottom: '-8px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: '0',
                  height: '0',
                  borderLeft: '8px solid transparent',
                  borderRight: '8px solid transparent',
                  borderTop: '8px solid #222',
                }} />
              </div>
            </Html>
          )}
        </>
      )}

      <group ref={groupRef} scale={[0.35, 0.35, 0.35]} dispose={null}>
        {model && <primitive object={model} />}
      </group>
      <mesh position={[0, 1.2, 0]} visible={false}>
        <capsuleGeometry args={[0.5, 1.5, 4]} />
        <meshStandardMaterial transparent opacity={0} />
      </mesh>
    </RigidBody>
  );
};

export default OtherPlayer;
