import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from './IconButton';
import { faThLarge, faMessage, faUser, faBars, faMicrophoneLines } from '@fortawesome/free-solid-svg-icons';
import { useDialogue } from '../contexts/DialogueContext';
import { GlobalContext } from '../contexts/GlobalStore';
import ProfileMenu from '../../ui-containers/ProfileMenu';
import MicrophoneMenu from '../../ui-containers/MicrophoneMenu';
import './Nav.css';
import TextChat from './TextChat';
import TextChatMenu from '../../ui-containers/TextChatMenu';
import SideDrawer from './SideDrawer';

const Navigation = () => {
  const navigate = useNavigate();
  const { isTextChatOpen, setTextChatOpen, isChatUIOpen, setChatUIOpen } = useDialogue();
  const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [isvCDropdownOpen, setVCDropdownOpen] = useState(false);
  const { isLoggedIn, isMobileView } = useContext(GlobalContext);
  const [isOpen, setIsOpen] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      // Reset the scroll position to the top on any device
      window.scrollTo(0, 0);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  const toggleDrawer = () => setIsOpen(!isOpen);

  const handleNavItemClick = (url) => {
    if (url && url !== '#') {
      navigate(url);
    }
  };

  const handleIconClick = (iconName) => {
    if (iconName === 'text') {
      setTextChatOpen(!isTextChatOpen);
      setProfileDropdownOpen(false);
      setVCDropdownOpen(false);
    } else if (iconName === 'voice') {
      setVCDropdownOpen(!isvCDropdownOpen);
      setProfileDropdownOpen(false);
    } else if (iconName === 'user') {
      setProfileDropdownOpen(!isProfileDropdownOpen);
      setVCDropdownOpen(false);
    }
  };

  const closeProfileMenu = () => {
    setProfileDropdownOpen(false);
  };

  const navItems = [
    { name: 'Gallery', url: '/' },
    { name: 'Music', url: '' }, // This will be disabled
    { name: 'Theatre', url: '#' }, // This will be disabled
  ];

  return (
    <div style={styles.Navigation}>
      <div style={styles.logoAndGhostContainer}>
        <img onClick={() => navigate(`/`)} style={styles.logo} src={process.env.PUBLIC_URL + '/assets/TargetNoFillWhite.png'} alt="Logo" />
        <div style={styles.ghostContainer}></div>
      </div>

      {!isMobileView && (
        <div style={styles.navItemsWrapper}>
          <div style={styles.navItemsContainer}>
            {navItems.map((item, index) => (
              <div
                key={index}
                style={{
                  ...styles.navItem,
                  color: item.url && item.url !== '#' ? 'white' : 'gray',
                  cursor: item.url && item.url !== '#' ? 'pointer' : 'default',
                }}
                onClick={() => handleNavItemClick(item.url)}
                onMouseEnter={() => setTooltipVisible(index)}
                onMouseLeave={() => setTooltipVisible(null)}
              >
                {item.name}
                {tooltipVisible === index && (!item.url || item.url === '#') && (
                  <div style={{ ...styles.tooltip, ...styles.tooltipVisible }}>
                    Coming Soon
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {isLoggedIn && (
        <div style={styles.iconWrapper}>
          <div style={styles.iconContainer}>
            <IconButton icon={faMessage} onClick={() => handleIconClick('text')} />
            <IconButton icon={faMicrophoneLines} onClick={() => handleIconClick('voice')} />
            <IconButton icon={faUser} onClick={() => handleIconClick('user')} />
            {isMobileView && (
              <IconButton icon={faBars} onClick={toggleDrawer} />
            )}
          </div>
        </div>
      )}

      {!isLoggedIn && (
        <div style={styles.authButtonsWrapper}>
          <div style={styles.authButtons}>
            <button style={styles.loginButton} onClick={() => navigate('/authenticate')}>
              Log In
            </button>
            <button style={styles.signUpButton}>
              Sign Up
            </button>
          </div>
        </div>
      )}

      <ProfileMenu isVisible={isProfileDropdownOpen} onClose={closeProfileMenu} />
      <MicrophoneMenu isVisible={isvCDropdownOpen} />
      <SideDrawer isOpen={isOpen} onClose={toggleDrawer} />
    </div>
  );
};

const Nav3D = () => {
  return (
    <>
      <Navigation />
    </>
  );
};

const styles = {
  mobileNav: {
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    color: 'white',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  menuIcon: {
    color: 'white',
    fontSize: 28,
    cursor: 'pointer',
  },
  Navigation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: 'rgba(1, 1, 1, 0)',
    color: 'white',
    padding: '16px 32px',
    boxSizing: 'border-box',
  },
  logoAndGhostContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '48px',
    backdropFilter: 'blur(24px)',
    WebkitBackdropFilter: 'blur(24px)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    transform: 'translateZ(0)', // Force hardware acceleration
  },
  navItemsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.2)', // Translucent background
    borderRadius: '48px', // Rounded corners
    padding: '4px',
    backdropFilter: 'blur(24px)',
    WebkitBackdropFilter: 'blur(24px)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Optional: Add a slight shadow for depth
    border: '1px solid rgba(255, 255, 255, 0.2)', // Add a white border with slight transparency
  },
  navItemsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  navItem: {
    margin: '0 20px',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: 'bold',
    fontSize: '16px',
    textTransform: 'uppercase',
    padding: '12px',
    position: 'relative',
  },
  logo: {
    height: '64px',
    width: 'auto',
    cursor: 'pointer',
  },
  ghostContainer: {
    flex: '1',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.2)', // Translucent background
    borderRadius: '32px', // Rounded corners
    padding: '2px 16px', // Adjust padding as needed
    backdropFilter: 'blur(24px)',
    WebkitBackdropFilter: 'blur(24px)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Optional: Add a slight shadow for depth
    border: '1px solid rgba(255, 255, 255, 0.2)', // Add a white border with slight transparency
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  authButtonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.2)', // Translucent background
    borderRadius: '40px', // Rounded corners
    padding: '8px 12px', // Adjust padding as needed
    backdropFilter: 'blur(24px)',
    WebkitBackdropFilter: 'blur(24px)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Optional: Add a slight shadow for depth
    border: '1px solid rgba(255, 255, 255, 0.3)', // Add a white border with slight transparency
  },
  authButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  loginButton: {
    cursor: 'pointer',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    padding: '8px 16px',
    borderRadius: '20px',
    border: 'none',
    color: 'white',
    marginRight: '10px',
    fontFamily: 'Work Sans',
    fontSize: '14px',
  },
  signUpButton: {
    backgroundColor: 'rgba(255, 255, 255, 0.0)',
    padding: '8px 16px',
    borderRadius: '20px',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    color: 'white',
    cursor: 'pointer',
    fontFamily: 'Work Sans',
    fontSize: '14px',
  },
  sideDrawer: {
    position: 'fixed',
    top: 0,
    right: 0,
    width: '50%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 10px',
    boxSizing: 'border-box',
    transform: 'translateX(0)',
    backdropFilter: 'blur(24px)',
    WebkitBackdropFilter: 'blur(24px)', // Added for iOS Safari
    border: '1px solid rgba(255, 255, 255, 0.3)',
    transition: 'transform 0.3s ease-out',
    borderRadius: '12px',
    zIndex: 1002, // Ensure it is above everything else
  },
  drawerOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dimmed background
    zIndex: 1001, // Just below the side drawer
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end', // pushes the close button to the right
  },
  closeButton: {
    cursor: 'pointer',
    fontSize: '30px',
    color: 'white',
    marginBottom: '20px',
  },
  tooltip: {
    position: 'absolute',
    bottom: '-30px', // Adjust based on your design
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#1f1f1f',
    color: '#ffffff',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    padding: '5px 10px',
    borderRadius: '8px',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    zIndex: 9999,
    opacity: 0,
    transition: 'opacity 0.2s ease',
  },
  tooltipVisible: {
    opacity: 1,
  },
};

export default Nav3D;
