import React, { useEffect, useState, useContext } from 'react';
import { useSocket } from '../contexts/SocketContext';
import OtherPlayer from './OtherPlayer';
import { usePlayerPositions } from '../contexts/PlayerPositionsProvider';
import { LiveKitContext } from '../contexts/LiveKitContext';
import * as THREE from 'three';
import { useDialogue } from '../contexts/DialogueContext';
import { GlobalContext } from '../contexts/GlobalStore';

const Multiplayer = () => {
  const { room } = useSocket();
  const [players, setPlayers] = useState({}); // all players state
  const { updateOtherPlayerPosition } = usePlayerPositions(); // for storing other players positions, enabling us to detect distance
  const { positionalAudios, activeSpeakers, setPositionalAudios } = useContext(LiveKitContext); // grab positional audio objects from LiveKitContext
  const { setChatUIOpen } = useDialogue(); // from your dialogue context
  const { nearbyPlayers } = usePlayerPositions();
  const { proximityChatUsers, addProximityChatUser, removeProximityChatUser } = useContext(GlobalContext); // Add this line

  // Effect hook to listen for room events and handle player state
  useEffect(() => {
    if (!room) return;

    // Listening for a player joining the game
    room.onMessage('playerJoin', (playerData) => {
      console.log('Player join:', playerData);
      setPlayers((players) => ({ ...players, [playerData.id]: playerData }));
    });

    // Listening for the list of all players
    room.onMessage('playerList', (playerList) => {
      //console.log('Received playerList:', playerList);
      setPlayers(playerList);
    });

    // Listening for player updates (position, rotation, etc.)
    room.onMessage('playerUpdate', (playerData) => {
      updateOtherPlayerPosition(playerData.id, playerData.position);
      setPlayers((prevPlayers) => {
        // Check for changes in player data, return data if change detected
        if (JSON.stringify(prevPlayers[playerData.id]) !== JSON.stringify(playerData)) {
          return {
            ...prevPlayers,
            [playerData.id]: {
              ...prevPlayers[playerData.id],
              ...playerData
            }
          };
        }
        return prevPlayers;
      });
    });

    room.onMessage('chatMessage', (data) => {
      console.log(`Chat message received from ${data.senderId}:`, data.message); // Log received messages
      setPlayers(prevPlayers => {
        const player = prevPlayers[data.senderId];
        if (player) {
          return {
            ...prevPlayers,
            [data.senderId]: {
              ...player,
              lastMessage: data.message,
              messageTimeout: Date.now() + 5000 // Display the message for 5 seconds
            }
          };
        }
        return prevPlayers;
      });
    });

    // Handling a player leaving the game
    room.onMessage('playerLeave', (playerId) => {
      setPlayers((players) => {
        console.log('Player leave:', playerId);
        const newPlayers = { ...players };
        delete newPlayers[playerId];
        return newPlayers;
      });
      removeProximityChatUser(playerId);
    });

    // Signaling readiness to receive player list
    room.send('readyForPlayerList');

  }, [room, removeProximityChatUser]);

  // Cleanup expired messages
  useEffect(() => {
    const interval = setInterval(() => {
      setPlayers(prevPlayers => {
        const updatedPlayers = { ...prevPlayers };
        for (let id in updatedPlayers) {
          if (updatedPlayers[id].messageTimeout && Date.now() > updatedPlayers[id].messageTimeout) {
            console.log(`Clearing message for player ${id}`);
            updatedPlayers[id].lastMessage = null;
            updatedPlayers[id].messageTimeout = null;
          }
        }
        return updatedPlayers;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  //console.log(nearbyPlayersRef);
  // Proximity check and add/remove chat users
  useEffect(() => {
    if (!room) return;
    nearbyPlayers.forEach(playerId => {
      const userToAdd = players[playerId];
      if (userToAdd && userToAdd.profile && userToAdd.profile.username !== 'guest' && !proximityChatUsers.some(user => user.id === playerId)) {
        addProximityChatUser({
          id: playerId,
          userName: userToAdd.userName,
          profile: userToAdd.profile,
        });
      }
    });
  
    // Remove players that are not nearby
    proximityChatUsers.forEach(user => {
      if (!nearbyPlayers.includes(user.id) && user.id !== room.sessionId) {
        removeProximityChatUser(user.id);
      }
    });
  }, [nearbyPlayers, proximityChatUsers, addProximityChatUser, removeProximityChatUser, players, room]);
  
  // Effect to monitor profile changes and handle guest profile
  useEffect(() => {
    const guestId = "guest-id";
    Object.entries(players).forEach(([id, playerData]) => {
      if (playerData.profile && playerData.profile.id === guestId) {
        removePositionalAudio(id);
      }
    });
  }, [players]);

  // Function to remove positional audio for a given player ID
  const removePositionalAudio = (playerId) => {
    setPositionalAudios((current) => {
      const newPositionalAudios = { ...current };
      delete newPositionalAudios[playerId];
      return newPositionalAudios;
    });
  };

  // Rendering other players with their updated positions and rotations
  return (
    <>
        {Object.entries(players)
        .filter(([id, playerData]) => playerData.position !== undefined && playerData.rotation !== undefined)
        .map(([id, playerData]) => {
          if (id === room.sessionId) { // only render other players. If the player id matches your personal session id, no player data is returned
            return null;
          }
          const playerAudio = positionalAudios[id]; // only return the relevant positonal audio for each player
          const receivedRotationAngle = playerData.rotation;
          const remoteCharacterQuaternion = new THREE.Quaternion();
          remoteCharacterQuaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), receivedRotationAngle);

          // slightly smooth the movement
          const interpolatedPosition = [
              playerData.position.x * 0.999,
              playerData.position.y * 0.999,
              playerData.position.z * 0.999,
          ];

          return (
              <OtherPlayer
                key={id}
                id={id}
                position={interpolatedPosition}
                quaternion={remoteCharacterQuaternion}
                animation={playerData.animation}
                color={playerData.color || "#5F5F5F"}
                positionalAudio={playerAudio}
                isActiveSpeaker={activeSpeakers.has(id)}
                userName={playerData.userName}
                profile={playerData.profile}
                lastMessage={playerData.lastMessage}
              />
            );
        })}
    </>
  );
};

export default Multiplayer;
