/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAvatar = /* GraphQL */ `
  query GetAvatar($id: ID!) {
    getAvatar(id: $id) {
      id
      Profile {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          nextToken
          __typename
        }
        following {
          nextToken
          __typename
        }
        connections {
          nextToken
          __typename
        }
        pointsRecords {
          nextToken
          __typename
        }
        totalPoints
        inventory {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      model
      createdAt
      updatedAt
      avatarProfileId
      owner
      __typename
    }
  }
`;
export const listAvatars = /* GraphQL */ `
  query ListAvatars(
    $filter: ModelAvatarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAvatars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Profile {
          id
          username
          name
          instagram
          twitter
          bio
          baseAvatarColor
          totalPoints
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        model
        createdAt
        updatedAt
        avatarProfileId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      username
      name
      instagram
      twitter
      Avatar {
        id
        Profile {
          id
          username
          name
          instagram
          twitter
          bio
          baseAvatarColor
          totalPoints
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        model
        createdAt
        updatedAt
        avatarProfileId
        owner
        __typename
      }
      bio
      baseAvatarColor
      followers {
        items {
          id
          followerId
          followeeId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      following {
        items {
          id
          followerId
          followeeId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      connections {
        items {
          id
          user1Id
          user2Id
          createdAt
          updatedAt
          profileConnectionsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      pointsRecords {
        items {
          id
          userId
          reason
          amount
          createdAt
          updatedAt
          profilePointsRecordsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      totalPoints
      inventory {
        items {
          id
          ownerId
          productId
          acquiredDate
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      profileAvatarId
      owner
      __typename
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          nextToken
          __typename
        }
        following {
          nextToken
          __typename
        }
        connections {
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFollow = /* GraphQL */ `
  query GetFollow($id: ID!) {
    getFollow(id: $id) {
      id
      follower {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          nextToken
          __typename
        }
        following {
          nextToken
          __typename
        }
        connections {
          nextToken
          __typename
        }
        pointsRecords {
          nextToken
          __typename
        }
        totalPoints
        inventory {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      followee {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          nextToken
          __typename
        }
        following {
          nextToken
          __typename
        }
        connections {
          nextToken
          __typename
        }
        pointsRecords {
          nextToken
          __typename
        }
        totalPoints
        inventory {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      followerId
      followeeId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listFollows = /* GraphQL */ `
  query ListFollows(
    $filter: ModelFollowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        follower {
          id
          username
          name
          instagram
          twitter
          bio
          baseAvatarColor
          totalPoints
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        followee {
          id
          username
          name
          instagram
          twitter
          bio
          baseAvatarColor
          totalPoints
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        followerId
        followeeId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const followsByFollowerIdAndFolloweeId = /* GraphQL */ `
  query FollowsByFollowerIdAndFolloweeId(
    $followerId: ID!
    $followeeId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFollowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    followsByFollowerIdAndFolloweeId(
      followerId: $followerId
      followeeId: $followeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        follower {
          id
          username
          name
          instagram
          twitter
          bio
          baseAvatarColor
          totalPoints
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        followee {
          id
          username
          name
          instagram
          twitter
          bio
          baseAvatarColor
          totalPoints
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        followerId
        followeeId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const followsByFolloweeIdAndFollowerId = /* GraphQL */ `
  query FollowsByFolloweeIdAndFollowerId(
    $followeeId: ID!
    $followerId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFollowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    followsByFolloweeIdAndFollowerId(
      followeeId: $followeeId
      followerId: $followerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        follower {
          id
          username
          name
          instagram
          twitter
          bio
          baseAvatarColor
          totalPoints
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        followee {
          id
          username
          name
          instagram
          twitter
          bio
          baseAvatarColor
          totalPoints
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        followerId
        followeeId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConnection = /* GraphQL */ `
  query GetConnection($id: ID!) {
    getConnection(id: $id) {
      id
      user1 {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          nextToken
          __typename
        }
        following {
          nextToken
          __typename
        }
        connections {
          nextToken
          __typename
        }
        pointsRecords {
          nextToken
          __typename
        }
        totalPoints
        inventory {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      user1Id
      user2 {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          nextToken
          __typename
        }
        following {
          nextToken
          __typename
        }
        connections {
          nextToken
          __typename
        }
        pointsRecords {
          nextToken
          __typename
        }
        totalPoints
        inventory {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      user2Id
      createdAt
      updatedAt
      profileConnectionsId
      owner
      __typename
    }
  }
`;
export const listConnections = /* GraphQL */ `
  query ListConnections(
    $filter: ModelConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConnections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user1 {
          id
          username
          name
          instagram
          twitter
          bio
          baseAvatarColor
          totalPoints
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        user1Id
        user2 {
          id
          username
          name
          instagram
          twitter
          bio
          baseAvatarColor
          totalPoints
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        user2Id
        createdAt
        updatedAt
        profileConnectionsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const connectionsByUser1IdAndUser2Id = /* GraphQL */ `
  query ConnectionsByUser1IdAndUser2Id(
    $user1Id: ID!
    $user2Id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    connectionsByUser1IdAndUser2Id(
      user1Id: $user1Id
      user2Id: $user2Id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user1 {
          id
          username
          name
          instagram
          twitter
          bio
          baseAvatarColor
          totalPoints
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        user1Id
        user2 {
          id
          username
          name
          instagram
          twitter
          bio
          baseAvatarColor
          totalPoints
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        user2Id
        createdAt
        updatedAt
        profileConnectionsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const connectionsByUser2IdAndUser1Id = /* GraphQL */ `
  query ConnectionsByUser2IdAndUser1Id(
    $user2Id: ID!
    $user1Id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    connectionsByUser2IdAndUser1Id(
      user2Id: $user2Id
      user1Id: $user1Id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user1 {
          id
          username
          name
          instagram
          twitter
          bio
          baseAvatarColor
          totalPoints
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        user1Id
        user2 {
          id
          username
          name
          instagram
          twitter
          bio
          baseAvatarColor
          totalPoints
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        user2Id
        createdAt
        updatedAt
        profileConnectionsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPoints = /* GraphQL */ `
  query GetPoints($id: ID!) {
    getPoints(id: $id) {
      id
      user {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          nextToken
          __typename
        }
        following {
          nextToken
          __typename
        }
        connections {
          nextToken
          __typename
        }
        pointsRecords {
          nextToken
          __typename
        }
        totalPoints
        inventory {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      userId
      reason
      amount
      createdAt
      updatedAt
      profilePointsRecordsId
      owner
      __typename
    }
  }
`;
export const listPoints = /* GraphQL */ `
  query ListPoints(
    $filter: ModelPointsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPoints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          username
          name
          instagram
          twitter
          bio
          baseAvatarColor
          totalPoints
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        userId
        reason
        amount
        createdAt
        updatedAt
        profilePointsRecordsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const pointsByUserId = /* GraphQL */ `
  query PointsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPointsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pointsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user {
          id
          username
          name
          instagram
          twitter
          bio
          baseAvatarColor
          totalPoints
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        userId
        reason
        amount
        createdAt
        updatedAt
        profilePointsRecordsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCreator = /* GraphQL */ `
  query GetCreator($id: ID!) {
    getCreator(id: $id) {
      id
      World {
        id
        name
        Spaces {
          nextToken
          __typename
        }
        Creator {
          id
          name
          createdAt
          updatedAt
          creatorWorldId
          __typename
        }
        createdAt
        updatedAt
        worldCreatorId
        __typename
      }
      Products {
        items {
          id
          creatorID
          itemName
          stripeProductId
          description
          price
          tribePointsPrice
          imageUrl
          stock
          productType
          glbUrl
          createdAt
          updatedAt
          creatorProductsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      name
      createdAt
      updatedAt
      creatorWorldId
      __typename
    }
  }
`;
export const listCreators = /* GraphQL */ `
  query ListCreators(
    $filter: ModelCreatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreators(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        World {
          id
          name
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Products {
          nextToken
          __typename
        }
        name
        createdAt
        updatedAt
        creatorWorldId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      creatorID
      creator {
        id
        World {
          id
          name
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Products {
          nextToken
          __typename
        }
        name
        createdAt
        updatedAt
        creatorWorldId
        __typename
      }
      itemName
      stripeProductId
      description
      price
      tribePointsPrice
      imageUrl
      stock
      inventoryItems {
        items {
          id
          ownerId
          productId
          acquiredDate
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      productType
      glbUrl
      createdAt
      updatedAt
      creatorProductsId
      owner
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        creatorID
        creator {
          id
          name
          createdAt
          updatedAt
          creatorWorldId
          __typename
        }
        itemName
        stripeProductId
        description
        price
        tribePointsPrice
        imageUrl
        stock
        inventoryItems {
          nextToken
          __typename
        }
        productType
        glbUrl
        createdAt
        updatedAt
        creatorProductsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productsByCreatorID = /* GraphQL */ `
  query ProductsByCreatorID(
    $creatorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByCreatorID(
      creatorID: $creatorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creatorID
        creator {
          id
          name
          createdAt
          updatedAt
          creatorWorldId
          __typename
        }
        itemName
        stripeProductId
        description
        price
        tribePointsPrice
        imageUrl
        stock
        inventoryItems {
          nextToken
          __typename
        }
        productType
        glbUrl
        createdAt
        updatedAt
        creatorProductsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInventoryItem = /* GraphQL */ `
  query GetInventoryItem($id: ID!) {
    getInventoryItem(id: $id) {
      id
      owner {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          nextToken
          __typename
        }
        following {
          nextToken
          __typename
        }
        connections {
          nextToken
          __typename
        }
        pointsRecords {
          nextToken
          __typename
        }
        totalPoints
        inventory {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      ownerId
      product {
        id
        creatorID
        creator {
          id
          name
          createdAt
          updatedAt
          creatorWorldId
          __typename
        }
        itemName
        stripeProductId
        description
        price
        tribePointsPrice
        imageUrl
        stock
        inventoryItems {
          nextToken
          __typename
        }
        productType
        glbUrl
        createdAt
        updatedAt
        creatorProductsId
        owner
        __typename
      }
      productId
      acquiredDate
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInventoryItems = /* GraphQL */ `
  query ListInventoryItems(
    $filter: ModelInventoryItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInventoryItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner {
          id
          username
          name
          instagram
          twitter
          bio
          baseAvatarColor
          totalPoints
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        ownerId
        product {
          id
          creatorID
          itemName
          stripeProductId
          description
          price
          tribePointsPrice
          imageUrl
          stock
          productType
          glbUrl
          createdAt
          updatedAt
          creatorProductsId
          owner
          __typename
        }
        productId
        acquiredDate
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoryItemsByOwnerId = /* GraphQL */ `
  query InventoryItemsByOwnerId(
    $ownerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryItemsByOwnerId(
      ownerId: $ownerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner {
          id
          username
          name
          instagram
          twitter
          bio
          baseAvatarColor
          totalPoints
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        ownerId
        product {
          id
          creatorID
          itemName
          stripeProductId
          description
          price
          tribePointsPrice
          imageUrl
          stock
          productType
          glbUrl
          createdAt
          updatedAt
          creatorProductsId
          owner
          __typename
        }
        productId
        acquiredDate
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoryItemsByProductId = /* GraphQL */ `
  query InventoryItemsByProductId(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryItemsByProductId(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner {
          id
          username
          name
          instagram
          twitter
          bio
          baseAvatarColor
          totalPoints
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        ownerId
        product {
          id
          creatorID
          itemName
          stripeProductId
          description
          price
          tribePointsPrice
          imageUrl
          stock
          productType
          glbUrl
          createdAt
          updatedAt
          creatorProductsId
          owner
          __typename
        }
        productId
        acquiredDate
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      productID
      userID
      paymentMethod
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productID
        userID
        paymentMethod
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWorld = /* GraphQL */ `
  query GetWorld($id: ID!) {
    getWorld(id: $id) {
      id
      name
      Spaces {
        items {
          id
          title
          wID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Creator {
        id
        World {
          id
          name
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Products {
          nextToken
          __typename
        }
        name
        createdAt
        updatedAt
        creatorWorldId
        __typename
      }
      createdAt
      updatedAt
      worldCreatorId
      __typename
    }
  }
`;
export const listWorlds = /* GraphQL */ `
  query ListWorlds(
    $filter: ModelWorldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorlds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        Spaces {
          nextToken
          __typename
        }
        Creator {
          id
          name
          createdAt
          updatedAt
          creatorWorldId
          __typename
        }
        createdAt
        updatedAt
        worldCreatorId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSpace = /* GraphQL */ `
  query GetSpace($id: ID!) {
    getSpace(id: $id) {
      id
      title
      wID
      World {
        id
        name
        Spaces {
          nextToken
          __typename
        }
        Creator {
          id
          name
          createdAt
          updatedAt
          creatorWorldId
          __typename
        }
        createdAt
        updatedAt
        worldCreatorId
        __typename
      }
      Events {
        items {
          id
          name
          type
          spaceID
          createdAt
          updatedAt
          eventWorldId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSpaces = /* GraphQL */ `
  query ListSpaces(
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        wID
        World {
          id
          name
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Events {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const spacesByWID = /* GraphQL */ `
  query SpacesByWID(
    $wID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spacesByWID(
      wID: $wID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        wID
        World {
          id
          name
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Events {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      name
      type
      spaceID
      Comments {
        items {
          id
          content
          eventID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      World {
        id
        name
        Spaces {
          nextToken
          __typename
        }
        Creator {
          id
          name
          createdAt
          updatedAt
          creatorWorldId
          __typename
        }
        createdAt
        updatedAt
        worldCreatorId
        __typename
      }
      Space {
        id
        title
        wID
        World {
          id
          name
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Events {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      eventWorldId
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        spaceID
        Comments {
          nextToken
          __typename
        }
        World {
          id
          name
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Space {
          id
          title
          wID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        eventWorldId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventsBySpaceID = /* GraphQL */ `
  query EventsBySpaceID(
    $spaceID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsBySpaceID(
      spaceID: $spaceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        spaceID
        Comments {
          nextToken
          __typename
        }
        World {
          id
          name
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Space {
          id
          title
          wID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        eventWorldId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      content
      eventID
      Event {
        id
        name
        type
        spaceID
        Comments {
          nextToken
          __typename
        }
        World {
          id
          name
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Space {
          id
          title
          wID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        eventWorldId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        eventID
        Event {
          id
          name
          type
          spaceID
          createdAt
          updatedAt
          eventWorldId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const commentsByEventID = /* GraphQL */ `
  query CommentsByEventID(
    $eventID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByEventID(
      eventID: $eventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        eventID
        Event {
          id
          name
          type
          spaceID
          createdAt
          updatedAt
          eventWorldId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
