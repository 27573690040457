import React, { createContext, useState, useEffect, useCallback } from 'react';
import { listEvents, getEvent, listWorlds, getProfile, listProfiles, followsByFollowerIdAndFolloweeId, connectionsByUser1IdAndUser2Id, connectionsByUser2IdAndUser1Id } from '../../graphql/queries';
import { deleteProfile, createFollow, deleteFollow, createConnection, deleteConnection, createPoints, updateProfile } from '../../graphql/mutations';
import { generateClient } from 'aws-amplify/api';
import { getCurrentUser } from "aws-amplify/auth";
import userEvent from '@testing-library/user-event';
import { Hub } from 'aws-amplify/utils';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [eventsData, setEventsData] = useState([]);
  const [worldsData, setWorldsData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [loading, setLoading] = useState(true);
  const pageSize = 6;
  const [userProfile, setUserProfile] = useState(null);

  // Inside GlobalProvider
  const [eventDetails, setEventDetails] = useState(null);
  const [eventLoading, setEventLoading] = useState(false);

  const client = generateClient();
  const publicClient = generateClient({ authMode: 'apiKey' });

  const [proximityChatUsers, setProximityChatUsers] = useState([]);

  const [selectedUserProfile, setSelectedUserProfile] = useState(null);

  const [currentUserProfile, setCurrentUserProfile] = useState(null);

  const [followActionTrigger, setFollowActionTrigger] = useState(Date.now());

  const [hasSpokenNear, setHasSpokenNear] = useState({});

  const [isLoggedIn, setIsLoggedIn] = useState(null);

  const [pointsNotification, setPointsNotification] = useState({ reason: '', points: 0 });
  
  const [avatarColor, setAvatarColor] = useState('#ffffff');

  // State to track window width
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1024);

  // Update the mobile view state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    const triggerPointsNotification = (reason, points) => {
        setPointsNotification({ reason, points });

        setTimeout(() => {
            setPointsNotification({ reason: '', points: 0 });
        }, 5000); // Reset notification after 5 seconds
    };

  // Add methods to update this trigger
  const triggerFollowAction = () => {
    setFollowActionTrigger(Date.now());
  };

  useEffect(() => {
    fetchUserProfile().then(profile => setCurrentUserProfile(profile));
  }, []);

  async function fetchUserProfile() {
    try {
      const currentUser = await getCurrentUser();
      const userId = currentUser.username; // or currentUser.attributes.sub

      const response = await client.graphql({
        query: listProfiles,
      });

      const profiles = response.data.listProfiles.items;
      const userProfile = profiles.find(profile => profile.owner === userId);

      if (userProfile) {
        setIsLoggedIn(true);
        setAvatarColor(userProfile.baseAvatarColor);
      } else {
        setIsLoggedIn(false);
      }

      return userProfile || null; // Return the userProfile object or null if not found
    } catch (error) {
      if (error.message.includes('User needs to be authenticated')) {
        console.log('User is not authenticated, setting profile to null.');
        setIsLoggedIn(false);
        return null;
      }
      console.error('Error fetching user profile:', error);
      return null;
    }
  }

  useEffect(() => {
  const initializeAuthState = async () => {
    try {
      const user = await getCurrentUser();
      console.log("User from global context", user);
      if (user) {
        setIsLoggedIn(true);
        fetchUserProfile(); // Fetch the user's profile if logged in
      } else {
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.error('Error fetching current user', error);
      setIsLoggedIn(false);
    }
  };

  initializeAuthState();

  // Adding this will ensure we check authentication state every time the app is initialized
  const checkUserSession = async () => {
    try {
      const user = await getCurrentUser();
      if (user) {
        console.log('User is logged in:', user);
      } else {
        console.log('No current user');
        setIsLoggedIn(false);
      }
    } catch (err) {
      console.log('Error fetching current user', err);
      setIsLoggedIn(false);
    }
  };

  checkUserSession();
}, []);


  // Listen for authentication events
  useEffect(() => {
    const listener = (data) => {
      const { payload } = data;
      if (payload.event === "signedIn") {
        setIsLoggedIn(true);
        window.location.href = '/';
      }
      if (payload.event === "signedOut") {
        setIsLoggedIn(false);
        console.log("user has signed out");
        window.location.href = '/';
      }
      console.log("Auth event:", payload.event, "with payload", payload);
    };

    // Start listening for auth events
    const hubListenerCancel = Hub.listen("auth", listener);

    // Cleanup listener on component unmount
    return () => hubListenerCancel();
  }, []);

  const addProximityChatUser = (user) => {
    setProximityChatUsers(prevUsers => {
      // Check if the user already exists in the list based on userId
      const userExists = prevUsers.some(existingUser => existingUser.id === user.id);
      if (!userExists) {
        // If the user doesn't exist, add them to the list
        return [...prevUsers, user];
      }
      // If the user already exists, return the list unchanged
      return prevUsers;
    });
  };

  async function checkUserProfile() {
    try {
      const currentUser = await getCurrentUser();
      console.log("curr user", currentUser);
      const userId = currentUser.username; // or currentUser.attributes.sub
      console.log("Current User ID:", userId);

      const response = await client.graphql({
        query: listProfiles,
      });

      console.log("List Profiles Response:", response);

      const profiles = response.data.listProfiles.items;
      const userProfile = profiles.find(profile => profile.owner === userId);

      setUserProfile(userProfile || null);

      console.log("Matching User Profile:", userProfile);

      return userProfile ? true : false;
    } catch (error) {
      console.error('Error checking user profiles:', error);
      return false;
    }
  }

  const removeProximityChatUser = (userId) => {
    setProximityChatUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
  };

  const openUserProfile = (userProfile) => {
    setSelectedUserProfile(userProfile);
  };

  const closeUserProfile = () => {
    setSelectedUserProfile(null);
  };


  /* async function deleteProfileById(profileId) {
    try {
      const deleteInput = {
        input: {
          id: profileId
        }
      };
      await client.graphql({
        query: deleteProfile,
        variables: deleteInput
      });
      console.log(`Profile with ID ${profileId} deleted`);
    } catch (error) {
      console.error(`Error deleting profile with ID ${profileId}:`, error);
    }
  }
  
  // Example usage
  async function deleteMultipleProfiles(profileIds) {
    for (const id of profileIds) {
      await deleteProfileById(id);
    }
  }
  
  // Call this function with an array of profile IDs you want to delete
  deleteMultipleProfiles(['0d3e556d-0004-4951-abb5-52c95867bd00', '58713e24-bba7-43b9-bfd5-c24c7562b9fa']); */

  useEffect(() => {
    const loadEvents = async () => {
      setLoading(true);
      try {
        const result = await publicClient.graphql({
          query: listEvents.replaceAll("__typename", ""),
          variables: { limit: pageSize },
        });
        console.log(result);
        if (result.data && result.data.listEvents) {
          setEventsData(result.data.listEvents.items);
          setHasMorePages(!!result.data.listEvents.nextToken);
        } else {
          console.error("No data received from the query");
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
      setLoading(false);
    };

    loadEvents();
  }, [pageIndex]);

  const handleFollowUser = async (followeeId) => {
    try {
      const input = { followerId: currentUserProfile.id, followeeId };
      await client.graphql({
        query: createFollow,
        variables: { input },
      });
      console.log("Successfully followed the user");
      // Update UI or state as needed
    } catch (error) {
      console.error("Error following user:", error);
    }
  };

  const handleUnfollowUser = async (followRecordId) => {
    try {
      await client.graphql({
        query: deleteFollow,
        variables: { input: { id: followRecordId } },
      });
      console.log("Successfully unfollowed the user");
    } catch (error) {
      console.error("Error unfollowing user:", error);
    }
  };

  const checkIfFollowing = async (followerId, followeeId) => {
    try {
      // Note: Ensure your GraphQL operation name and variables match the query expectations.
      //console.log("follow and followee", followerId, followeeId);
      const response = await client.graphql({
        query: followsByFollowerIdAndFolloweeId,
        variables: {
          followerId: followerId,
          followeeId: { eq: followeeId }
        },
      });
      //console.log("folling forward", response.data);
      return response.data.followsByFollowerIdAndFolloweeId.items.length > 0;
    } catch (error) {
      console.error("Error checking if following:", error);
      return false; // Assume not following in case of error
    }
  };

  const handleCreateConnection = async (user1Id, user2Id) => {
    // Assuming createConnection mutation is correctly set up in your GraphQL API
    let response = await client.graphql({
      query: createConnection,
      variables: { input: { user1Id, user2Id } },
    });
    console.log("Connection created", response);
    triggerFollowAction();
  };

  const handleDeleteConnection = async (connectionId) => {
    try {
      await client.graphql({
        query: deleteConnection,
        variables: { input: { id: connectionId } },
      });
      console.log("Connection deleted");
      triggerFollowAction(); // Use to trigger UI updates
    } catch (error) {
      console.error("Error deleting connection:", error);
      triggerFollowAction();
    }
  };

  const findConnectionId = async (user1Id, user2Id) => {
    try {
      // Check from user1 to user2
      let response = await client.graphql({
        query: connectionsByUser1IdAndUser2Id,
        variables: {
          user1Id: user1Id,
          user2Id: { eq: user2Id }
        },
      });

      let connection = response.data.connectionsByUser1IdAndUser2Id.items.find(item => item.user2Id === user2Id);

      if (!connection) {
        // Check from user2 to user1 if not found in the first query
        response = await client.graphql({
          query: connectionsByUser2IdAndUser1Id,
          variables: {
            user2Id: user1Id,
            user1Id: { eq: user2Id }
          },
        });

        connection = response.data.connectionsByUser2IdAndUser1Id.items.find(item => item.user1Id === user2Id);
      }

      return connection ? connection.id : null;
    } catch (error) {
      console.error("Error finding connection:", error);
      return null;
    }
  };

  // Method to handle mutual follow checks and connection creation
  const manageConnectionOnFollow = async (followerId, followeeId) => {
    const isFollowingBack = await checkIfFollowing(followeeId, followerId);
    const isFollowing = await checkIfFollowing(followerId, followeeId);

    console.log("Follower:", followerId, "Followee:", followeeId);
    console.log("Is following back:", isFollowingBack, "Is following:", isFollowing);

    // Create a connection if there's mutual following
    if (isFollowingBack && isFollowing) {
      await handleCreateConnection(followerId, followeeId);
      const pointsAmount = 10; // Define how many points you want to award
      const reason = 'New Connection Made';
      await awardPoints(followerId, pointsAmount, reason);
      await awardPoints(followeeId, pointsAmount, reason);
      triggerPointsNotification(`New Connection Made`, 10);
    } else if (!isFollowingBack && isFollowing) {
      triggerPointsNotification(`Connection Pending`, 0);
    }
  };

  // Method to handle connection deletion on unfollow
  const manageConnectionOnUnfollow = async (followerId, followeeId) => {
    const connectionId = await findConnectionId(followerId, followeeId);
    if (connectionId) {
      await handleDeleteConnection(connectionId);
      const pointsAmount = 10; // Define how many points you want to deduct
      await deductPointsForUnfollow(followerId, pointsAmount);
      await deductPointsForUnfollow(followeeId, pointsAmount);
    }
  };

  const awardPoints = async (userId, points, reason) => {
    try {
      const createPointsInput = {
        userId,
        amount: points,
        reason,
      };
      await client.graphql({
        query: createPoints,
        variables: { input: createPointsInput },
      });
  
      // Optionally, update the user's total points
      const userProfile = await client.graphql({
        query: getProfile,
        variables: { id: userId },
      });
  
      if (userProfile.data.getProfile) {
        const updatedTotalPoints = userProfile.data.getProfile.totalPoints + points;
        await client.graphql({
          query: updateProfile,
          variables: { input: { id: userId, totalPoints: updatedTotalPoints } },
        });
      }
      console.log(`Points awarded to user ${userId}`);
    } catch (error) {
      console.error(`Error awarding points to user ${userId}:`, error);
    }
  };
  
  const deductPointsForUnfollow = async (userId, points, reason = 'Connection removed') => {
    try {
      // Assuming negative points to deduct
      const createPointsInput = {
        userId,
        amount: -points,
        reason,
      };
      await client.graphql({
        query: createPoints,
        variables: { input: createPointsInput },
      });
  
      // Optionally, update the user's total points
      const userProfile = await client.graphql({
        query: getProfile,
        variables: { id: userId },
      });
  
      if (userProfile.data.getProfile) {
        const updatedTotalPoints = userProfile.data.getProfile.totalPoints - points;
        await client.graphql({
          query: updateProfile,
          variables: { input: { id: userId, totalPoints: updatedTotalPoints } },
        });
      }
      console.log(`Points deducted from user ${userId}`);
    } catch (error) {
      console.error(`Error deducting points from user ${userId}:`, error);
    }
  };  

  const getFollowRecordId = async (followerProfileId, followeeProfileId) => {
    try {
      const response = await client.graphql({
        query: followsByFollowerIdAndFolloweeId,
        variables: {
          followerId: followerProfileId,
          followeeId: { eq: followeeProfileId }, // This is based on the structure of your GraphQL API
        },
      });
      const follows = response.data.followsByFollowerIdAndFolloweeId.items;
      return follows.length > 0 ? follows[0].id : null; // Return the first follow record's ID or null if not found
    } catch (error) {
      console.error("Error retrieving follow record ID:", error);
      return null;
    }
  };


  useEffect(() => {
    const loadWorlds = async () => {
      setLoading(true);
      try {
        const result = await publicClient.graphql({
          query: listWorlds.replaceAll("__typename", ""),
          variables: { limit: pageSize },
        });
        console.log(result);
        if (result.data && result.data.listWorlds) {
          setWorldsData(result.data.listWorlds.items);
          setHasMorePages(!!result.data.listWorlds.nextToken);
        } else {
          console.error("No data received from the query");
        }
      } catch (error) {
        console.error("Error fetching Worlds:", error);
      }
      setLoading(false);
    };

    loadWorlds();
  }, [pageIndex]);

  return (
    <GlobalContext.Provider value={{
      eventsData, setEventsData, worldsData, setWorldsData, pageIndex, setPageIndex, checkUserProfile, awardPoints, triggerPointsNotification, pointsNotification, isMobileView,
      hasMorePages, loading, eventDetails, setEventDetails, eventLoading, fetchUserProfile, proximityChatUsers, avatarColor, setAvatarColor,
      addProximityChatUser, removeProximityChatUser, setProximityChatUsers, selectedUserProfile, openUserProfile, closeUserProfile, handleFollowUser, isLoggedIn,
      handleUnfollowUser, currentUserProfile, setCurrentUserProfile, checkIfFollowing, getFollowRecordId, followActionTrigger, triggerFollowAction, handleCreateConnection, handleDeleteConnection, manageConnectionOnFollow, manageConnectionOnUnfollow, hasSpokenNear, setHasSpokenNear, findConnectionId
    }}>
      {children}
    </GlobalContext.Provider>
  );
};