import React from 'react';
import { CSSTransition } from 'react-transition-group';
import './Modal.css'; // Add your custom styles here
import ProfilePage from './ProfilePage';

const ProfileModal = ({ isVisible, onClose }) => {
  return (
    <CSSTransition
      in={isVisible}
      timeout={200}
      classNames="fade"
      unmountOnExit
    >
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <ProfilePage />
          <button className="modal-close-button" onClick={onClose}>×</button>
        </div>
      </div>
    </CSSTransition>
  );
};

export default ProfileModal;
