import React, { useState, useRef, useMemo, useEffect, useContext } from 'react';
import { Canvas } from '@react-three/fiber';
import './App.css';
import ThirdPersonCamera from './tspace_components/components/ThirdPersonCamera';
import Multiplayer from './tspace_components/components/Multiplayer';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ScenesHandler from './scenes/ScenesHandler';
import MobileJoystick from './tspace_components/components/MobileControls';
import Loading from './tspace_components/components/Loading';
import Nav3D from './tspace_components/components/Nav3D';
import VideoPlayerOverlay from './tspace_components/components/VideoPlayerOverlay';
import AudioPlayerOverlay from './tspace_components/components/AudioPlayerOverlay';
import { Authenticator } from '@aws-amplify/ui-react';
import { Hub } from "aws-amplify/utils";
import { getCurrentUser, fetchAuthSession,signOut } from 'aws-amplify/auth';
import { Physics } from '@react-three/rapier';
/* import AllProviders from './tspace_components/contexts/AllProviders'; */
import { useDialogue } from './tspace_components/contexts/DialogueContext';
import ProximityChat from './tspace_components/components/ProximityChat';
import { GlobalContext } from './tspace_components/contexts/GlobalStore';
import ProfileView from './tspace_components/components/ProfileView';
import PointsNotification from './tspace_components/components/PointsNotification';
import TextChat from './tspace_components/components/TextChat';
import { Stats } from '@react-three/drei';
import { Stat } from './ui-components';

const App = () => {
  const characterRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const key = useMemo(() => location.pathname, [location]);
  const navigate = useNavigate();
  const { isChatUIOpen, isTextChatOpen} = useDialogue();
  const { selectedUserProfile, closeUserProfile, isLoggedIn, pointsNotification } = useContext(GlobalContext);

  // Fetch user and session info on component mount
  useEffect(() => {
    fetchCurrentUser();
    currentSession();
    //signOut();
  }, []);

  // Fetch current user information
  async function fetchCurrentUser() {
    try {
      const user = await getCurrentUser();
      if (user) {
        console.log('Current User: ', user);
      }
    } catch (error) {
      console.error('Error fetching current user', error);
    }
  }

  async function checkUserAuthentication() {
    try {
      const user = await getCurrentUser();
      if (user) {
        console.log('User is authenticated:', user);
        // Additional logic for authenticated users
      } else {
        console.log('User is not authenticated.');
      }
    } catch (error) {
      console.error('Error fetching user session:', error);
    }
  }
  
  useEffect(() => {
    checkUserAuthentication();
  }, []);

  // Fetch current session tokens
  async function currentSession() {
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      console.log("access token", accessToken, "id token", idToken);
    } catch (err) {
      console.log(err);
    }
  }

  // Listen for authentication events
  useEffect(() => {
    const listener = (data) => {
      const { payload } = data;
      if (payload.event === "signedIn") {
        console.log("user has signed in");
        navigate("/");
      }
      if (payload.event === "signedOut") {
        console.log("user has signed out");
      }
      console.log("Auth event:", payload.event, "with payload", payload);
    };

    // Start listening for auth events
    const hubListenerCancel = Hub.listen("auth", listener);

    // Cleanup listener on component unmount
    return () => hubListenerCancel();
  }, [navigate]);

  // Main application render
  return (
    <>
    {/* <Authenticator> */}
      <Canvas gl={{ stencil: true }} key={key} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%' }}>
        <Physics key={key}>
          <ScenesHandler characterRef={characterRef} />
          <ThirdPersonCamera characterRef={characterRef} />
          <Multiplayer />
        </Physics>
      </Canvas>
      {location.pathname === '/Listen' && <AudioPlayerOverlay />}
      {pointsNotification.reason && (
          <PointsNotification reason={pointsNotification.reason} points={pointsNotification.points} />
      )}
      <MobileJoystick />
      <VideoPlayerOverlay />
      {isLoading && <Loading onLoadComplete={() => setIsLoading(false)} />}
      {selectedUserProfile && <ProfileView user={selectedUserProfile} onClose={closeUserProfile} />}
      {/* isChatUIOpen && */ isLoggedIn &&<ProximityChat />}
      <TextChat isVisible={isTextChatOpen && isLoggedIn} />
      <Nav3D />
      {/* <Stats/> */}
    {/* </Authenticator> */}
    </>
  );
};

export default App;